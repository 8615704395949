import {
  put, take, fork, call, takeLatest,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { Store as store } from 'react-notifications-component';
import Axios from 'axios';
import axios, { axiosClientNoGraphl } from '../../axios';
import config from '../../config/config';
import { failedNotificationTimer } from '../../config/constants';
import catchErrors from '../../graphql/sagas';

import {
  QueryAllFilteredProjects,
  QueryAllProjects,
  QueryProjectDetails,
  QueryProjectTypes,
  QueryProjectCalls,
  QueryProjectWorkPackets,
  updateOneProject,
  createOneProject,
  addWorkPacketToProject,
  deleteProjectWorkPacket,
  updateProjectWorkPacket,
  addPartnerToProjectMutation,
  removePartnerFromProjectMutation,
  addTeamMemberToProjectMutation,
  removeTeamMemberFromProjectMutation,
  removeProject as removeProjectQuery,
} from '../../graphql/queries/project';
import {
  FETCH_ALL_PROJECTS,
  FETCH_ALL_PROJECTS_SUCCESS,
  FETCH_ALL_PROJECTS_ERROR,
  FETCH_PROJECT_TYPES_SUCCESS,
  FETCH_PROJECT_TYPES_ERROR,
  FETCH_PROJECT_SUCCESS,
  FETCH_PROJECT_ERROR,
  FETCH_PROJECT_CALLS_SUCCESS,
  FETCH_PROJECT_CALLS_ERROR,
  FETCH_PROJECT_WORKPACKETS_SUCCESS,
  FETCH_PROJECT_WORKPACKETS_ERROR,
  FETCH_PROJECT_FILES_SUCCESS,
  FETCH_PROJECT_FILES_ERROR,
  POST_PROJECT_FILE_SUCCESS,
  POST_PROJECT_FILE_ERROR,
  DELETE_PROJECT_FILE_ERROR,
  DELETE_PROJECT_FILE_SUCCESS,
  REMOVE_PROJECT_PARTNER_ERROR,
  REMOVE_PROJECT_PARTNER_SUCCESS,
  ADD_PROJECT_PARTNER_SUCCESS,
  ADD_PROJECT_PARTNER_ERROR,
  ADD_PROJECT_MEMBER_ERROR,
  ADD_PROJECT_MEMBER_SUCCESS,
  REMOVE_PROJECT_MEMBER_ERROR,
  REMOVE_PROJECT_MEMBER_SUCCESS,

  REMOVE_PROJECT,
  removeProjectError,
  removeProjectSuccess,
} from './projectActions';

export const getFilter = (state) => state.project.filter;
let cancelToken;
function* fetchAllProjects() {
  try {
    if (typeof cancelToken !== typeof undefined) {
      cancelToken.cancel('Operation canceled due to new request.');
    }
    cancelToken = Axios.CancelToken.source();

    const response = yield call(axios.post, '/', { query: QueryAllProjects }, { cancelToken: cancelToken.token });

    yield put(FETCH_ALL_PROJECTS_SUCCESS(response.data.data.filteredProjects));
  } catch (e) {
    if (!Axios.isCancel(e)) {
      yield put(FETCH_ALL_PROJECTS_ERROR(e));
    }
  }
}

function* fetchProjectDetails(action) {
  try {
    const response = yield call(axios.post, '/', { query: QueryProjectDetails(action.selectedId) });
    yield put(FETCH_PROJECT_SUCCESS(response.data.data.project));
  } catch (e) {
    yield put(FETCH_PROJECT_ERROR(e));
    yield put(push({
      pathname: '/admin/projects/notfound',
      state: {
        entity: 'project',
        id: action.data.id,
      },
    }));
  }
}

function* fetchProjectTypes() {
  try {
    const response = yield call(axios.post, '/', { query: QueryProjectTypes });
    yield put(FETCH_PROJECT_TYPES_SUCCESS(response.data.data.types));
  } catch (e) {
    yield put(FETCH_PROJECT_TYPES_ERROR(e));
  }
}

function* fetchProjectCalls() {
  try {
    const response = yield call(axios.post, '/', { query: QueryProjectCalls });
    yield put(FETCH_PROJECT_CALLS_SUCCESS(response.data.data));
  } catch (e) {
    yield put(FETCH_PROJECT_CALLS_ERROR(e));
  }
}

function* fetchAllProjectsWithFilter(action) {
  try {
    if (typeof cancelToken !== typeof undefined) {
      cancelToken.cancel('Operation canceled due to new request.');
    }
    cancelToken = Axios.CancelToken.source();

    const filters = action.data;

    const response = yield call(axios.post, '/', { query: QueryAllFilteredProjects(filters) }, { cancelToken: cancelToken.token });
    yield put(FETCH_ALL_PROJECTS_SUCCESS(response.data.data.filteredProjects));
  } catch (e) {
    if (!Axios.isCancel(e)) {
      yield put(FETCH_ALL_PROJECTS_ERROR(e));
    }
  }
}

function* updateProject(action) {
  try {
    const response = yield call(axios.post, '/', { query: updateOneProject(action.data) });
    yield put(FETCH_PROJECT_SUCCESS(response.data.data.updateProject));
    store.addNotification({
      title: 'SUCCESS!',
      message: 'Update successfully saved!',
      type: 'success',
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: 2000,
        onScreen: true,
      },
    });
  } catch (e) {
    yield put(FETCH_PROJECT_ERROR(e));
    store.addNotification({
      title: 'ERROR!',
      message: 'Unable to update, entry!',
      type: 'danger',
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: failedNotificationTimer,
        onScreen: true,
      },
    });
  }
}

function* createProject(action) {
  try {
    const response = yield call(axios.post, '/', { query: createOneProject(action.data) });
    const { id } = response.data.data.createProject;
    yield put(FETCH_PROJECT_SUCCESS(response.data.data.createProject));
    store.addNotification({
      title: 'SUCCESS!',
      message: 'Update successfully saved!',
      type: 'success',
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: 1000,
        onScreen: true,
      },
    });
    yield put(push(`/admin/projects/${id}`));
  } catch (e) {
    yield put(FETCH_PROJECT_ERROR(e));
    store.addNotification({
      title: 'ERROR!',
      message: 'Unable to create project',
      type: 'danger',
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: failedNotificationTimer,
        onScreen: true,
      },
    });
  }
}

function* fetchWorkPackets(action) {
  try {
    const response = yield call(axios.post, '/', { query: QueryProjectWorkPackets(action.id) });
    yield put(FETCH_PROJECT_WORKPACKETS_SUCCESS(response.data.data.project.workPackets));
  } catch (e) {
    yield put(FETCH_PROJECT_WORKPACKETS_ERROR(e));
  }
}

function* addWorkPacket(action) {
  try {
    yield call(axios.post, '/', { query: addWorkPacketToProject(action.data) });
    yield fork(fetchWorkPackets, { id: action.data.projectId });
  } catch {
    store.addNotification({
      title: 'ERROR!',
      message: 'Failed to add Work Packet',
      type: 'danger',
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: failedNotificationTimer,
        onScreen: true,
      },
    });
  }
}

function* updateWorkPacket(action) {
  try {
    yield call(axios.post, '/', { query: updateProjectWorkPacket(action.data) });
    yield fork(fetchWorkPackets, { id: action.data.projectId });
  } catch {
    store.addNotification({
      title: 'ERROR!',
      message: 'Failed to add Work Packet',
      type: 'danger',
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: failedNotificationTimer,
        onScreen: true,
      },
    });
  }
}

function* deleteWorkPacket(action) {
  try {
    yield call(axios.post, '/', { query: deleteProjectWorkPacket(action.id) });
  } catch {
    store.addNotification({
      title: 'ERROR!',
      message: `Failed to delete Work Packet [id: ${action.id}]`,
      type: 'danger',
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: failedNotificationTimer,
        onScreen: true,
      },
    });
  }
}

function* fetchFiles(action) {
  try {
    const response = yield call(axiosClientNoGraphl.get, `/files/listFiles/project/${action.id}`);
    yield put(FETCH_PROJECT_FILES_SUCCESS(response.data));
  } catch (e) {
    yield put(FETCH_PROJECT_FILES_ERROR(e));
    store.addNotification({
      title: 'ERROR!',
      message: `Failed to fetch files [id: ${action.id}]`,
      type: 'danger',
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: failedNotificationTimer,
        onScreen: true,
      },
    });
  }
}

function* addFile(action) {
  try {
    const response = yield call(axiosClientNoGraphl.post, '/files/uploadFile', action.data, { headers: { 'content-type': 'multipart/form-data' } });
    yield put(POST_PROJECT_FILE_SUCCESS(response.data, action.data.get('attribute')));
  } catch (e) {
    yield put(POST_PROJECT_FILE_ERROR(e, action.data.get('attribute')));
    store.addNotification({
      title: 'ERROR!',
      message: 'Failed to add file',
      type: 'danger',
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: failedNotificationTimer,
        onScreen: true,
      },
    });
  }
}

function* deleteFile(action) {
  try {
    yield call(axiosClientNoGraphl.delete, `/files/deleteFile/project/${action.data.id}/${action.data.attribute}/${action.data.fileName}`);
    yield put(DELETE_PROJECT_FILE_SUCCESS(action.data));
  } catch (e) {
    yield put(DELETE_PROJECT_FILE_ERROR(e));
    store.addNotification({
      title: 'ERROR!',
      message: 'Failed to delete file',
      type: 'danger',
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: failedNotificationTimer,
        onScreen: true,
      },
    });
  }
}

function downloadFile(action) {
  try {
    window.open(`${config.API_URL}/files/downloadFile/project/${action.data.id}/${action.data.attribute}/${action.data.fileName}`);
  } catch {
    store.addNotification({
      title: 'ERROR!',
      message: 'Failed to download file',
      type: 'danger',
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: failedNotificationTimer,
        onScreen: true,
      },
    });
  }
}

function* deleteProjectPartner(action) {
  try {
    const response = yield call(axios.post, '/', { query: removePartnerFromProjectMutation(action.data) });
    yield put(REMOVE_PROJECT_PARTNER_SUCCESS(response));
  } catch (e) {
    yield put(REMOVE_PROJECT_PARTNER_ERROR(e));
    store.addNotification({
      title: 'ERROR!',
      message: 'Failed to remove partner',
      type: 'danger',
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: failedNotificationTimer,
        onScreen: true,
      },
    });
  }
}

function* addProjectPartner(action) {
  try {
    const response = yield call(axios.post, '/', { query: addPartnerToProjectMutation(action.data) });
    yield put(ADD_PROJECT_PARTNER_SUCCESS(response));
  } catch (e) {
    yield put(ADD_PROJECT_PARTNER_ERROR(e));
    store.addNotification({
      title: 'ERROR!',
      message: 'Failed to add partner',
      type: 'danger',
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: failedNotificationTimer,
        onScreen: true,
      },
    });
  }
}

function* deleteProjectMember(action) {
  try {
    const response = yield call(axios.post, '/', { query: removeTeamMemberFromProjectMutation(action.data) });
    yield put(REMOVE_PROJECT_MEMBER_SUCCESS(response));
  } catch (e) {
    yield put(REMOVE_PROJECT_MEMBER_ERROR(e));
    store.addNotification({
      title: 'ERROR!',
      message: 'Failed to remove team member',
      type: 'danger',
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: failedNotificationTimer,
        onScreen: true,
      },
    });
  }
}

function* addProjectMember(action) {
  try {
    const response = yield call(axios.post, '/', { query: addTeamMemberToProjectMutation(action.data) });
    yield put(ADD_PROJECT_MEMBER_SUCCESS(response));
  } catch (e) {
    yield put(ADD_PROJECT_MEMBER_ERROR(e));
    store.addNotification({
      title: 'ERROR!',
      message: 'Failed to add team member',
      type: 'danger',
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: failedNotificationTimer,
        onScreen: true,
      },
    });
  }
}

export function* fetchProjectsSagaWatcher() {
  while (true) {
    const action = yield take('FETCH_ALL_PROJECTS');
    yield fork(fetchAllProjects, action);
  }
}

export function* fetchProjectSagaWatcher() {
  while (true) {
    const action = yield take('FETCH_PROJECT');
    yield fork(fetchProjectDetails, action);
  }
}

export function* fetchAllProjectsWithFilterSagaWatcher() {
  while (true) {
    const action = yield take(['SET_PROJECT_FILTERS']);
    yield fork(fetchAllProjectsWithFilter, action);
  }
}

export function* fetchProjectTypesSagaWatcher() {
  while (true) {
    const action = yield take(['FETCH_PROJECT_TYPES']);
    yield fork(fetchProjectTypes, action);
  }
}

export function* fetchProjectCallsSagaWatcher() {
  while (true) {
    const action = yield take(['FETCH_PROJECT_CALLS']);
    yield fork(fetchProjectCalls, action);
  }
}

export function* clearProjectFiltersSagaWatcher() {
  while (true) {
    const action = yield take('CLEAR_PROJECT_FILTERS');
    yield fork(fetchAllProjects, action);
  }
}

export function* updateProjectSagaWatcher() {
  while (true) {
    const action = yield take('UPDATE_ONE_PROJECT');
    yield fork(updateProject, action);
  }
}

export function* createProjectSagaWatcher() {
  while (true) {
    const action = yield take('CREATE_ONE_PROJECT');
    yield fork(createProject, action);
  }
}

export function* addWorkPacketToProjectSagaWatcher() {
  while (true) {
    const action = yield take('ADD_WORKPACKETS_TO_PROJECT');
    yield fork(addWorkPacket, action);
  }
}

export function* updateWorkPacketProjectSagaWatcher() {
  while (true) {
    const action = yield take('UPDATE_PROJECT_WORKPACKET');
    yield fork(updateWorkPacket, action);
  }
}

export function* deleteWorkPacketToProjectSagaWatcher() {
  while (true) {
    const action = yield take('DELETE_PROJECT_WORKPACKET');
    yield fork(deleteWorkPacket, action);
  }
}

export function* fetchProjectWorkPacketsSagaWatcher() {
  while (true) {
    const action = yield take('FETCH_PROJECT_WORKPACKETS');
    yield fork(fetchWorkPackets, action);
  }
}

export function* fetchFilesSagaWatcher() {
  while (true) {
    const action = yield take('FETCH_PROJECT_FILES');
    yield fork(fetchFiles, action);
  }
}

export function* addFileSagaWatcher() {
  while (true) {
    const action = yield take('POST_PROJECT_FILE');
    yield fork(addFile, action);
  }
}

export function* deleteFileSagaWatcher() {
  while (true) {
    const action = yield take('DELETE_PROJECT_FILE');
    yield fork(deleteFile, action);
  }
}

export function* downloadFileSagaWatcher() {
  while (true) {
    const action = yield take('DOWNLOAD_PROJECT_FILE');
    yield fork(downloadFile, action);
  }
}

export function* deleteProjectPartnerSagaWatcher() {
  while (true) {
    const action = yield take('REMOVE_PROJECT_PARTNER');
    yield fork(deleteProjectPartner, action);
  }
}

export function* addProjectPartnerSagaWatcher() {
  while (true) {
    const action = yield take('ADD_PROJECT_PARTNER');
    yield fork(addProjectPartner, action);
  }
}

export function* deleteProjectMemberSagaWatcher() {
  while (true) {
    const action = yield take('REMOVE_PROJECT_MEMBER');
    yield fork(deleteProjectMember, action);
  }
}

export function* addProjectMemberSagaWatcher() {
  while (true) {
    const action = yield take('ADD_PROJECT_MEMBER');
    yield fork(addProjectMember, action);
  }
}

export function* removeProject({ projectId }) {
  yield call(axios.post, '/', { query: removeProjectQuery(projectId) });
  yield put(removeProjectSuccess());
  yield put(FETCH_ALL_PROJECTS());
  store.addNotification({
    title: 'Deleted!',
    message: 'Project was deleted succesfully',
    type: 'success',
    insert: 'top',
    container: 'top-right',
    dismiss: {
      duration: 3000,
      onScreen: true,
    },
  });
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(
    REMOVE_PROJECT,
    catchErrors({
      onErrorAction: removeProjectError,
      overrideErrorMessage: 'Unable to remove the project',
    })(removeProject),
  );
}
