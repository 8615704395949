import { serializeMoment } from '../../utils';

export const QueryALlYearsAndPeople = `
{
  allDates
  allPeopleWithoutLists{
    id
    first
    last
    unumber
   }
}`;

export const QueryPublicationPeople = `
  {
      allPeoplePeople{
        id
        first
        last
        userid
       }
  }
`;

export const QueryAllPublications = (ids, years, publicationDateFrom, publicationDateTo, conferenceDateFrom, conferenceDateTo, authors, types, limitInternational, limitReviewed, limitAuthor, statuses, teamId) => (`
{
  publicationsFilterAdmin(
    ids:[${ids}], 
    years:[${years}], 
    publicationDateFrom: ${serializeMoment(publicationDateFrom)}, 
    publicationDateTo: ${serializeMoment(publicationDateTo)},
    conferenceDateFrom: ${serializeMoment(conferenceDateFrom)}, 
    conferenceDateTo: ${serializeMoment(conferenceDateTo)},
    types:[${types}], authors:[${authors}], limitInternational:${limitInternational}, limitReviewed: ${limitReviewed}, limitAuthor: ${limitAuthor}, statuses: [${statuses}], teamid: ${teamId || null}
    ) {
    publicationByYear {
      date
      publications {
        patents {
          id
          title
          description
          date
          timestamp
          webVersion
          webVersionSourceCode
          webVersionRecording
          visibility
          hideFromPublic
          status
          authors {
            first
            last
            author
            cosic
          }
          files {
            fileName
            visibility
            path
          }
          number
          applicant
        }
        abstractOfTalks {
          id
          title
          pages
          city
          state
          country
          volume
          edition
          from
          proceedings
          proceedingsFrom
          publisher
          webVersion
          webVersionSourceCode
          webVersionRecording
          visibility
          hideFromPublic
          status
          authors {
            first
            last
            author
            cosic
          }
          files {
            fileName
            visibility
            path
          }
        }
        talks {
          id
          title
          event
          city
          state
          country
          from
          webVersion
          webVersionSourceCode
          webVersionRecording
          visibility
          hideFromPublic
          status
          authors {
            first
            last
            author
            cosic
          }
          files {
            fileName
            visibility
            path
          }
        }
        theses {
          id
          title
          type
          institution
          pages
          date
          webVersion
          webVersionSourceCode
          webVersionRecording
          visibility
          hideFromPublic
          status
          authors {
            first
            last
            author
            cosic
          }
          promotors {
            first
            last
            author
            cosic
          }
          files {
            fileName
            visibility
            path
          }
        }
        proceedings {
          id
          volume
          conference
          appeared
          edition
          proceedings
          seriesName
          city
          country
          publisher
          webVersion
          webVersionSourceCode
          webVersionRecording
          visibility
          hideFromPublic
          status
          editors {
            first
            last
            author
            cosic
          }
          files {
            fileName
            visibility
            path
          }
        }
        rumps {
          id
          title
          pages
          from
          proceedings
          edition
          webVersion
          webVersionSourceCode
          webVersionRecording
          visibility
          hideFromPublic
          status
          authors {
            first
            last
            author
            cosic
          }
          files {
            fileName
            visibility
            path
          }
        }
        books {
          id
          title
          publisher
          volume
          date
          pages
          seriesName
          webVersion
          webVersionSourceCode
          webVersionRecording
          visibility
          hideFromPublic
          status
          authors {
            first
            last
            author
            cosic
          }
          editors {
            first
            last
            author
            cosic
          }
          files {
            fileName
            visibility
            path
          }
        }
        reports {
          id
          title
          pages
          date
          note
          webVersion
          webVersionSourceCode
          webVersionRecording
          visibility
          hideFromPublic
          status
          authors {
            first
            last
            author
            cosic
          }
          files {
            fileName
            visibility
            path
          }
        }
        bookArticles {
          id
          title
          pages
          bookTitle
          date
          publisher
          seriesName
          webVersion
          webVersionSourceCode
          webVersionRecording
          visibility
          hideFromPublic
          status
          authors{
            first
            last
            author
            cosic
          }
          editors {
            first
            last
            author
            cosic
          }
          files {
            fileName
            visibility
            path
          }
        }
        journalArticles {
          id
          title
          pages
          number
          date
          from
          volume
          journalTitle
          webVersion
          webVersionSourceCode
          webVersionRecording
          visibility
          hideFromPublic
          status
          authors {
            first
            last
            author
            cosic
          }
          files {
            fileName
            visibility
            path
          }
        }
        conferenceArticles {
          id
          title
          pages
          proceedingid
          city
          state
          country
          edition
          volume
          from
          publisher
          conference
          series
          acronym
          proceedingTitle
          proceedingsFrom
          webVersion
          webVersionSourceCode
          webVersionRecording
          visibility
          hideFromPublic
          status
          authors {
            first
            last
            author
            cosic
          }
          editors {
            first
            last
            author
            cosic
          }
          files {
            fileName
            visibility
            path
          }
        }
      }
    }
    publicationByAuthor {
      author
      publications {
        patents {
          id
          title
          description
          date
          timestamp
          authors {
            first
            last
            author
            cosic
          }
          number
          applicant
          visibility
          hideFromPublic
          status
          webVersionRecording
          files {
            fileName
            visibility
            path
          }
        }
        abstractOfTalks {
          id
          title
          pages
          city
          state
          country
          volume
          edition
          from
          proceedings
          proceedingsFrom
          publisher
          visibility
          hideFromPublic
          status
          webVersionRecording
          authors {
            first
            last
            author
            cosic
          }
          files {
            fileName
            visibility
            path
          }
        }
        talks {
          id
          title
          event
          city
          state
          country
          from
          visibility
          hideFromPublic
          status
          webVersionRecording
          authors {
            first
            last
            author
            cosic
          }
          files {
            fileName
            visibility
            path
          }
        }
        theses {
          id
          title
          type
          pages
          institution
          date
          visibility
          hideFromPublic
          status
          webVersionRecording
          authors {
            first
            last
            author
            cosic
          }
          promotors {
            first
            last
            author
            cosic
          }
          files {
            fileName
            visibility
            path
          }
        }
        proceedings {
          id
          volume
          conference
          appeared
          edition
          proceedings
          seriesName
          publisher
          city
          country
          visibility
          hideFromPublic
          status
          webVersionRecording
          editors {
            first
            last
            author
            cosic
          }
          files {
            fileName
            visibility
            path
          }
        }
        rumps {
          id
          title
          pages
          from
          proceedings
          edition
          visibility
          status
          webVersionRecording
          authors {
            first
            last
            author
            cosic
          }
          files {
            fileName
            visibility
            path
          }
        }
        books {
          id
          title
          publisher
          volume
          date
          pages
          seriesName
          visibility
          hideFromPublic
          status
          webVersionRecording
          authors {
            first
            last
            author
            cosic
          }
          editors {
            first
            last
            author
            cosic
          }
          files {
            fileName
            visibility
            path
          }
        }
        reports {
          id
          title
          pages
          date
          note
          visibility
          status
          webVersionRecording
          authors {
            first
            last
            author
            cosic
          }
          files {
            fileName
            visibility
            path
          }
        }
        bookArticles {
          id
          title
          pages
          bookTitle
          date
          publisher
          seriesName
          visibility
          hideFromPublic
          status
          webVersionRecording
          authors{
            first
            last
            author
            cosic
          }
          files {
            fileName
            visibility
            path
          }
        }
        journalArticles {
          id
          title
          pages
          number
          date
          from
          volume
          journalTitle
          visibility
          hideFromPublic
          status
          webVersionRecording
          authors {
            first
            last
            author
            cosic
          }
          files {
            fileName
            visibility
            path
          }
        }
        conferenceArticles {
          id
          title
          pages
          city
          state
          country
          volume
          from
          edition
          publisher
          conference
          series
          acronym
          proceedingTitle
          visibility
          hideFromPublic
          status
          webVersionRecording
          authors {
            first
            last
            author
            cosic
          }
          editors {
            first
            last
            author
            cosic
          }
          files {
            fileName
            visibility
            path
          }
        }
      }
    }
    publicationByCategory {
      articlesCategory {
        journalCategory {
          internationalReviewed {
            date
            journals {
              id
              title
              pages
              number
              from
              volume
              date
              journalTitle
              visibility
              hideFromPublic
              status
              webVersionRecording
              authors {
                first
                last
                author
                cosic
              }
              files {
                fileName
                visibility
                path
              }
            }
          }
          internationalNotReviewed {
            date
            journals {
              id
              title
              pages
              number
              from
              volume
              date
              journalTitle
              visibility
              hideFromPublic
              status
              webVersionRecording
              authors {
                first
                last
                author
                cosic
              }
              files {
                fileName
                visibility
                path
              }
            }
          }
          localReviewed {
            date
            journals {
              id
              title
              pages
              number
              from
              volume
              date
              journalTitle
              visibility
              hideFromPublic
              status
              webVersionRecording
              authors {
                first
                last
                author
                cosic
              }
              files {
                fileName
                visibility
                path
              }
            }
          }
          localNotReviewed {
            date
            journals {
              id
              title
              pages
              number
              from
              volume
              date
              journalTitle
              visibility
              hideFromPublic
              status
              webVersionRecording
              authors {
                first
                last
                author
                cosic
              }
              files {
                fileName
                visibility
                path
              }
            }
          }
        }
        conferenceCategory {
          internationalReviewed {
            date
            conferenceArticles {
              id
              title
              pages
              city
              state
              country
              volume
              edition
              from
              publisher
              conference
              series
              acronym
              proceedingTitle
              visibility
              hideFromPublic
              status
              webVersionRecording
              authors {
                first
                last
                author
                cosic
                
              }
              editors {
                first
                last
                author
                cosic
              }
              files {
                fileName
                visibility
                path
              }
            }
          }
          internationalNotReviewed {
            date
            conferenceArticles {
              id
              title
              pages
              city
              state
              edition
              country
              volume
              from
              publisher
              conference
              series
              acronym
              proceedingTitle
              visibility
              hideFromPublic
              status
              webVersionRecording
              authors {
                first
                last
                author
                cosic
              }
              editors {
                first
                last
                author
                cosic
              }
              files {
                fileName
                visibility
                path
              }
            }
          }
          localReviewed {
            date
            conferenceArticles {
              id
              title
              pages
              city
              state
              country
              volume
              edition
              from
              publisher
              conference
              series
              acronym
              proceedingTitle
              visibility
              hideFromPublic
              status
              webVersionRecording
              authors {
                first
                last
                author
                cosic
              }
              editors {
                first
                last
                author
                cosic
              }
              files {
                fileName
                visibility
                path
              }
            }
          }
          localNotReviewed {
            date
            conferenceArticles {
              id
              title
              pages
              city
              state
              country
              volume
              edition
              from
              publisher
              conference
              series
              acronym
              proceedingTitle
              visibility
              hideFromPublic
              status
              webVersionRecording
              authors {
                first
                last
                author
                cosic
              }
              editors {
                first
                last
                author
                cosic
              }
              files {
                fileName
                visibility
                path
              }
            }
          }
        }
        bookArticlesCategory {
          internationalReviewed {
            date
            bookArticleViews {
              id
              title
              pages
              bookTitle
              date
              publisher
              seriesName
              visibility
              hideFromPublic
              status
              webVersionRecording
              authors {
                first
                last
                author
                cosic
              }
              files {
                fileName
                visibility
                path
              }
            }
          }
          internationalNotReviewed {
            date
            bookArticleViews {
              id
              title
              pages
              bookTitle
              date
              publisher
              seriesName
              visibility
              hideFromPublic
              status
              webVersionRecording
              authors {
                first
                last
                author
                cosic
              }
              files {
                fileName
                visibility
                path
              }
            }
          }
          localReviewed {
            date
            bookArticleViews {
              id
              title
              pages
              bookTitle
              date
              publisher
              seriesName
              visibility
              hideFromPublic
              status
              webVersionRecording
              authors {
                first
                last
                author
                cosic
              }
              files {
                fileName
                visibility
                path
              }
            }
          }
          localNotReviewed {
            date
            bookArticleViews {
              id
              title
              pages
              bookTitle
              date
              publisher
              seriesName
              visibility
              hideFromPublic
              status
              webVersionRecording
              authors {
                first
                last
                author
                cosic
              }
              files {
                fileName
                visibility
                path
              }
            }
          }
        }
      }
      internalReport {
        date
        reports {
          id
          title
          pages
          date
          note
          visibility
          hideFromPublic
          status
          webVersionRecording
          authors {
            first
            last
            author
            cosic
          }
          files {
            fileName
            visibility
            path
          }
        }
      }
      books {
        internationalReviewed {
          date
          books {
            id
            title
            publisher
            volume
            date
            pages
            seriesName
            visibility
            hideFromPublic
            status
            webVersionRecording
            authors {
              first
              last
              author
              cosic
            }
            editors {
              first
              last
              author
              cosic
            }
            files {
              fileName
              visibility
              path
            }
          }
        }
        internationalNotReviewed {
          date
          books {
            id
            title
            publisher
            volume
            date
            pages
            seriesName
            visibility
            hideFromPublic
            status
            webVersionRecording
            authors {
              first
              last
              author
              cosic
            }
            editors {
              first
              last
              author
              cosic
            }
            files {
              fileName
              visibility
              path
            }
          }
        }
        localReviewed {
          date
          books {
            id
            title
            publisher
            volume
            date
            pages
            seriesName
            visibility
            hideFromPublic
            status
            webVersionRecording
            authors {
              first
              last
              author
              cosic
            }
            editors {
              first
              last
              author
              cosic
            }
            files {
              fileName
              visibility
              path
            }
          }
        }
        localNotReviewed {
          date
          books {
            id
            title
            publisher
            volume
            date
            pages
            seriesName
            status
            webVersionRecording
            authors {
              first
              last
              author
              cosic
            }
            editors {
              first
              last
              author
              cosic
            }
            files {
              fileName
              visibility
              path
            }
          }
        }
      }
      patents {
        date
        patents {
          id
          title
          description
          date
          timestamp
          visibility
          hideFromPublic
          status
          webVersionRecording
          authors {
            first
            last
            author
            cosic
          }
          number
          applicant
          files {
            fileName
            visibility
            path
          }
        }
      }
      proceedingCategory {
        internationalReviewed {
          date
          proceedings {
            id
            volume
            conference
            appeared
            edition
            proceedings
            seriesName
            city
            country
            publisher
            visibility
            hideFromPublic
            status
            webVersionRecording
            editors {
              first
              last
              author
              cosic
            }
            files {
              fileName
              visibility
              path
            }
          }
        }
        internationalNotReviewed {
          date
          proceedings {
            id
            volume
            conference
            appeared
            edition
            proceedings
            seriesName
            city
            country
            publisher
            visibility
            hideFromPublic
            status
            webVersionRecording
            editors {
              first
              last
              author
              cosic
            }
            files {
              fileName
              visibility
              path
            }
          }
        }
        localReviewed {
          date
          proceedings {
            id
            volume
            conference
            appeared
            edition
            proceedings
            seriesName
            city
            country
            publisher
            visibility
            hideFromPublic
            status
            webVersionRecording
            editors {
              first
              last
              author
              cosic
            }
            files {
              fileName
              visibility
              path
            }
          }
        }
        localNotReviewed {
          date
          proceedings {
            id
            volume
            conference
            appeared
            edition
            proceedings
            seriesName
            city
            country
            publisher
            visibility
            hideFromPublic
            status
            webVersionRecording
            editors {
              first
              last
              author
              cosic
            }
            files {
              fileName
              visibility
              path
            }
          }
        }
      }
      rumpCategory {
        internationalReviewed {
          date
          rumps {
            id
            title
            pages
            from
            proceedings
            edition
            visibility
            hideFromPublic
            status
            webVersionRecording
            authors {
              first
              last
              author
              cosic
            }
            files {
              fileName
              visibility
              path
            }
          }
        }
        internationalNotReviewed {
          date
          rumps {
            id
            title
            pages
            from
            proceedings
            edition
            visibility
            hideFromPublic
            status
            webVersionRecording
            authors {
              first
              last
              author
              cosic
            }
            files {
              fileName
              visibility
              path
            }
          }
        }
        localReviewed {
          date
          rumps {
            id
            title
            pages
            from
            proceedings
            edition
            visibility
            hideFromPublic
            status
            webVersionRecording
            authors {
              first
              last
              author
              cosic
            }
            files {
              fileName
              visibility
              path
            }
          }
        }
        localNotReviewed {
          date
          rumps {
            id
            title
            pages
            from
            proceedings
            edition
            visibility
            hideFromPublic
            status
            webVersionRecording
            authors {
              first
              last
              author
              cosic
            }
            files {
              fileName
              visibility
              path
            }
          }
        }
      }
      abstractOfTalkCategory {
        internationalReviewed {
          date
          abstractOfTalks {
            id
            title
            pages
            city
            state
            country
            volume
            edition
            from
            proceedings
            proceedingsFrom
            publisher
            visibility
            hideFromPublic
            status
            webVersionRecording
            authors {
              first
              last
              author
              cosic
            }
            files {
              fileName
              visibility
              path
            }
          }
        }
        internationalNotReviewed {
          date
          abstractOfTalks {
            id
            title
            pages
            city
            state
            country
            volume
            edition
            from
            proceedings
            proceedingsFrom
            publisher
            visibility
            hideFromPublic
            status
            webVersionRecording
            authors {
              first
              last
              author
              cosic
            }
            files {
              fileName
              visibility
              path
            }
          }
        }
        localReviewed {
          date
          abstractOfTalks {
            id
            title
            pages
            city
            state
            country
            volume
            edition
            from
            proceedings
            proceedingsFrom
            publisher
            visibility
            hideFromPublic
            status
            webVersionRecording
            authors {
              first
              last
              author
              cosic
            }
            files {
              fileName
              visibility
              path
            }
          }
        }
        localNotReviewed {
          date
          abstractOfTalks {
            id
            title
            pages
            city
            state
            country
            volume
            edition
            from
            proceedings
            proceedingsFrom
            publisher
            visibility
            hideFromPublic
            status
            webVersionRecording
            authors {
              first
              last
              author
              cosic
            }
            files {
              fileName
              visibility
              path
            }
          }
        }
      }
      talkCategory {
        internationalNotReviewed {
          date
          talks {
            id
            title
            event
            city
            state
            country
            from
            visibility
            hideFromPublic
            status
            webVersionRecording
            authors {
              first
              last
              author
              cosic
            }
            files {
              fileName
              visibility
              path
            }
          }
        }
        localNotReviewed {
          date
          talks {
            id
            title
            event
            city
            state
            country
            from
            visibility
            hideFromPublic
            status
            webVersionRecording
            authors {
              first
              last
              author
              cosic
            }
            files {
              fileName
              visibility
              path
            }
          }
        }
      }
      thesesCategory {
        phdTheses {
          date
          theses {
            id
            title
            type
            pages
            institution
            date
            visibility
            hideFromPublic
            status
            webVersionRecording
            authors {
              first
              last
              author
              cosic
            }
            promotors {
              first
              last
              author
              cosic
            }
            files {
              fileName
              visibility
              path
            }
          }
        }
        masterTheses {
          date
          theses {
            id
            title
            type
            pages
            institution
            date
            visibility
            hideFromPublic
            status
            webVersionRecording
            authors {
              first
              last
              author
              cosic
            }
            promotors {
              first
              last
              author
              cosic
            }
            files {
              fileName
              visibility
              path
            }
          }
        }
        bachelorTheses {
          date
          theses {
            id
            title
            type
            pages
            institution
            date
            visibility
            hideFromPublic
            status
            webVersionRecording
            authors {
              first
              last
              author
              cosic
            }
            promotors {
              first
              last
              author
              cosic
            }
            files {
              fileName
              visibility
              path
            }
          }
        }
      }
    }
  }
}
`);

const journalReturnObject = `
  {
    id
    title
    pages
    type
    status
    country
    attributes
    teams { id name }
    firstAuthor {
      id
      unumber
      first
      last
    }
    authors {
      id
      unumber
      first
      last
      
    }
    editors {
      id
      first
      last
      unumber
    }
    filetypes
    doi
    date
    acceptedDate
    complete
    reviewed
    preprint
    webVersion
    webVersionSourceCode
    webVersionRecording
    visibility
    hideFromPublic
    dataset
    datasetRDRStored
    earlyAccess
    publication {
      id
      appeared
      volume
      number
      journal {
        id
        acronym
        title
        publisher
        category
        issn
        attributes
      }
    }
    projects {
      id
      name
      acronym
    }
  }
`;

export const createJournalArticle = (data) => (`
mutation {
  createJournalArticle(article: {
    title: """${data.title}\n""",
    teamIds: [${data.teams.join(',')}]
    type: "journal",
    pages: "${data.pages}"
    attributes: "public,visible,cosic",
    filetypes: "pdf",
    status: "${data.status}",
    authors:[${data.authors.join(',')}]
    doi: "${data.doi}"
    date: "${data.date}"
    acceptedDate: "${data.acceptedDate}"
    webVersion: "${data.webVersion ? data.webVersion : ''}"
    webVersionSourceCode: "${data.webVersionSourceCode ? data.webVersionSourceCode : ''}"
    webVersionRecording: "${data.webVersionRecording ? data.webVersionRecording : ''}"
    country: ${data.country ? `"${data.country}"` : null}
    complete: ${data.complete}
    reviewed: ${data.reviewed}
    preprint: ${data.preprint}
    editors: [${data.editors.join(',')}]
    visibility: ${data.visibility}
    hideFromPublic: ${data.hideFromPublic}
    dataset: ${data.dataset}
    datasetRDRStored: ${data.datasetRDRStored}
    earlyAccess: ${data.earlyAccess}
  },
  volume: {
    journal: ${data.journal}
    volume: "${data.volume}"
    number: "${data.number}"
    from: "${data.date}"
    accuracy: "d"
    appeared: "${data.date}"
  },
  firstAuthor: ${data.primaryAuthor}
  )
  ${journalReturnObject}
}
`);

export const updateJournalArticle = (data) => (`
mutation {
  updateJournalArticle(article: {
    id: ${data.id},
    title: """${data.title}\n""",
    publication: ${data.publication},
    teamIds: [${data.teams.join(',')}]
    type: "journal",
    pages: "${data.pages}"
    attributes: "public,visible,cosic",
    filetypes: "pdf",
    status: "${data.status}",
    authors:[${data.authors.join(',')}]
    editors: [${data.editors.join(',')}]
    doi: "${data.doi}"
    date: "${data.date}"
    acceptedDate: "${data.acceptedDate}"
    webVersion: "${data.webVersion ? data.webVersion : ''}"
    webVersionSourceCode: "${data.webVersionSourceCode ? data.webVersionSourceCode : ''}"
    webVersionRecording: "${data.webVersionRecording ? data.webVersionRecording : ''}"
    country: ${data.country ? `"${data.country}"` : null}
    complete: ${data.complete}
    reviewed: ${data.reviewed}
    preprint: ${data.preprint}
    visibility: ${data.visibility}
    hideFromPublic: ${data.hideFromPublic}
    dataset: ${data.dataset}
    datasetRDRStored: ${data.datasetRDRStored}
    earlyAccess: ${data.earlyAccess}
  },
  volume: {
    id: ${data.publication}
    journal: ${data.journal}
    volume: "${data.volume}"
    number: "${data.number}"
    from: "${data.date}"
    accuracy: "d"
    appeared: "${data.date}"
  },
  firstAuthor: ${data.primaryAuthor},
  )
  ${journalReturnObject}
}
`);

export const createConferenceArticle = (data) => (`
  mutation{
    createConferenceArticle(article: {
      title: """${data.title}\n""",
      proceedingTitle: "${data.proceedingTitle ? data.proceedingTitle : ''}",
      status: "${data.status}",
      presentedAtRump: ${data.presentedAtRump}
      pages: "${data.pages}"
      complete: ${data.complete}
      reviewed: ${data.reviewed}
      attributes: "public,visible,cosic"
      filetypes: "pdf"
      webVersion: "${data.webVersion ? data.webVersion : ''}"
      webVersionSourceCode: "${data.webVersionSourceCode ? data.webVersionSourceCode : ''}"
      webVersionRecording: "${data.webVersionRecording ? data.webVersionRecording : ''}"
      authors:[${data.authors.join(',')}]
      doi: "${data.doi}"
      series: "${data.series}"
      teamIds: [${data.teams.join(',')}]
      date: "${data.date}"
      acceptedDate: "${data.acceptedDate}"
      publisher: "${data.publisher}"
      editors: [${data.editors.join(',')}]
      visibility: ${data.visibility}
      hideFromPublic: ${data.hideFromPublic}
      dataset: ${data.dataset}
      datasetRDRStored: ${data.datasetRDRStored}
      earlyAccess: ${data.earlyAccess}
    },
    proceedingsId: ${data.proceedingsId},
    firstAuthor: ${data.primaryAuthor},
    ){
      id
      title
      proceedingTitle
      presentedAtRump
      complete
      reviewed
      publisher
      visibility
      hideFromPublic
      dataset
      datasetRDRStored
      earlyAccess
      editors {
        id
        first
        last
        unumber
      }
      teams { id name }
      doi
      series
      webVersion
      webVersionSourceCode
      webVersionRecording
      date
      acceptedDate
      pages
      status
      projects {
        id
        name
      }
      firstAuthor {
        id
        first
        last
      }
      authors {
        id
        first
        last
        order
      }
      publication {
        id
        from
        to
        appeared
        edition
        volume
        city
        country
        state
        conference {
          id
          conference
          proceedings
        }
      }
    }
  }
`);

export const updateConferenceArticle = (data) => (`
  mutation{
    updateConferenceArticle(article: {
      id: ${data.id},
      title: """${data.title}\n""",
      status: "${data.status}",
      presentedAtRump: ${data.presentedAtRump}
      pages: "${data.pages}"
      complete: ${data.complete}
      reviewed: ${data.reviewed}
      attributes: "public,visible,cosic"
      filetypes: "pdf"
      webVersion: "${data.webVersion ? data.webVersion : ''}"
      webVersionSourceCode: "${data.webVersionSourceCode ? data.webVersionSourceCode : ''}"
      webVersionRecording: "${data.webVersionRecording ? data.webVersionRecording : ''}"
      authors:[${data.authors.join(',')}]
      doi: "${data.doi}"
      series: "${data.series}"
      teamIds: [${data.teams.join(',')}]
      date: "${data.date}"
      acceptedDate: "${data.acceptedDate}"
      publisher: "${data.publisher}"
      editors: [${data.editors.join(',')}]
      visibility: ${data.visibility}
      hideFromPublic: ${data.hideFromPublic}
      dataset: ${data.dataset}
      datasetRDRStored: ${data.datasetRDRStored}
      earlyAccess: ${data.earlyAccess}
    },
    proceedingsId: ${data.proceedingsId},
    firstAuthor: ${data.primaryAuthor},
    ){
      id
      title
      proceedingTitle
      presentedAtRump
      complete
      reviewed
      visibility
      hideFromPublic
      dataset
      datasetRDRStored
      earlyAccess
      editors {
        id
        first
        last
        unumber
      }
      teams { id name }
      doi
      series
      webVersion
      webVersionSourceCode
      webVersionRecording
      date
      acceptedDate
      pages
      status
      publisher
      projects {
        id
        name
      }
      firstAuthor {
        id
        first
        last
      }
      authors {
        id
        first
        last
        order
      }
      publication {
        id
        from
        to
        appeared
        edition
        volume
        city
        country
        state
        conference {
          id
          conference
          proceedings
        }
      }
    }
  }
`);

export const createProceeding = (data) => (`
mutation{
  createProceeding(proceeding: {
    conference: ${data.conference}
    ${data.edition ? `edition: ${data.edition}` : ''}
    ${data.volume ? `volume: "${data.volume}"` : ''}
    from: "${data.from}"
    to: "${data.to}"
    acceptedDate: "${data.acceptedDate}"
    city: "${data.city}"
    state: "${data.state}"
    country: "${data.country}"
    attributes: ""
    url: "${data.webVersion ? data.webVersion : ''}"
    appeared: "${data.date}"
    doi: "${data.doi}"
    teamIds: [${data.teams.join(',')}]
    editors: [${data.editors.join(',')}]
    webVersion: "${data.webVersion ? data.webVersion : ''}"
    webVersionSourceCode: "${data.webVersionSourceCode ? data.webVersionSourceCode : ''}"
    webVersionRecording: "${data.webVersionRecording ? data.webVersionRecording : ''}"
    status: "${data.status}"
    accuracy: "d"
    visibility: ${data.visibility}
    hideFromPublic: ${data.hideFromPublic}
    dataset: ${data.dataset}
    datasetRDRStored: ${data.datasetRDRStored}
    publisher: "${data.publisher}"
    title: """${data.title}\n"""
    series: ${data.series}
  })
  {
    id
    edition
    volume
    appeared
    acceptedDate
    visibility
    hideFromPublic
    dataset
    datasetRDRStored
    publisher
    editors {
      id
      first
      last
      unumber
    }
    country
    city
    doi
    state
    url
    webVersion
    webVersionSourceCode
    webVersionRecording
    status
    title
    projects {
      id
      name
    }
    teams { id name }
    conference {
      id
      acronym
      conference
      proceedings
      category
      booktitle
      series {
        id
        acronym
        name
        publisher
        issn
        link
        isiimpact
        aciimpact
        owner
        timestamp
      }
      publisher
      attributes
      isiimpact
      aciimpact
      owner
      timestamp
    }
  }
}
`);

export const updateProceeding = (data) => (`
mutation{
  updateProceeding(proceeding: {
    id: ${data.id},
    conference: ${data.conference}
    ${data.edition ? `edition: ${data.edition}` : ''}
    ${data.volume ? `volume: "${data.volume}"` : ''}
    from: "${data.from}"
    to: "${data.to}"
    acceptedDate: "${data.acceptedDate}"
    city: "${data.city}"
    state: "${data.state}"
    country: "${data.country}"
    attributes: ""
    url: "${data.webVersion ? data.webVersion : ''}"
    appeared: "${data.date}"
    doi: "${data.doi}"
    teamIds: [${data.teams.join(',')}]
    editors: [${data.editors.join(',')}]
    webVersion: "${data.webVersion ? data.webVersion : ''}"
    webVersionSourceCode: "${data.webVersionSourceCode ? data.webVersionSourceCode : ''}"
    webVersionRecording: "${data.webVersionRecording ? data.webVersionRecording : ''}"
    status: "${data.status}"
    accuracy: "d"
    visibility: ${data.visibility}
    hideFromPublic: ${data.hideFromPublic}
    dataset: ${data.dataset}
    datasetRDRStored: ${data.datasetRDRStored}
    publisher: "${data.publisher}"
    title: """${data.title}\n"""
    series: ${data.series}
  })
  {
    id
    edition
    volume
    appeared
    acceptedDate
    visibility
    from
    to
    hideFromPublic
    dataset
    datasetRDRStored
    editors {
      id
      first
      last
      unumber
    }
    country
    city
    doi
    state
    url
    webVersion
    webVersionSourceCode
    webVersionRecording
    status
    projects {
      id
      name
    }
    teams { id name }
    publisher
    title
    conference {
      id
      acronym
      conference
      proceedings
      category
      booktitle
      series {
        id
        acronym
        name
        publisher
        issn
        link
        isiimpact
        aciimpact
        owner
        timestamp
      }
      publisher
      attributes
      isiimpact
      aciimpact
      owner
      timestamp
    }
  }
}
`);

export const updateBook = (data) => (`
mutation{
  updateBook(book: {
    id: ${data.id},
    title: """${data.title}\n"""
    date: "${data.date}"
    acceptedDate: "${data.acceptedDate}"
    teamIds: [${data.teams.join(',')}]
    volume: "${data.volume}"
    status: "${data.status}"
    country: "${data.country}"
    publisher: "${data.publisher}"
    doi: "${data.doi}"
    pages: "${data.pages}"
    appeared: "${data.date}"
    authors: [${data.authors.join(',')}]
    editors: [${data.editors.join(',')}]
    webVersion: "${data.webVersion ? data.webVersion : ''}"
    webVersionSourceCode: "${data.webVersionSourceCode ? data.webVersionSourceCode : ''}"
    webVersionRecording: "${data.webVersionRecording ? data.webVersionRecording : ''}"
    isbn: "${data.isbn}"
    visibility: ${data.visibility}
    hideFromPublic: ${data.hideFromPublic}
    dataset: ${data.dataset}
    datasetRDRStored: ${data.datasetRDRStored}
  },
  firstAuthor: ${data.primaryAuthor},
  ){
    id
    title
    date
    acceptedDate
    appeared
    volume
    visibility
    hideFromPublic
    dataset
    datasetRDRStored
    teams { id name }
    authors {
      id
      first
      last
      order
    }
    editors {
      id
      first
      last
    }
    status
    pages
    doi
    country
    webVersion
    webVersionSourceCode
    webVersionRecording
    isbn
    firstAuthor{
      id
      first
      last
    }
    publisher
    projects {
      id
      name
    }
  }
}
`);

export const createBookArticle = (data) => (`
mutation{
  createBookArticle(input: {
    title: """${data.title}\n"""
    publication: ${data.book}
    status: "${data.status}"
    pages: "${data.pages}"
    attributes: "public,visible,cosic"
    filetypes: ""
    webVersion: "${data.webVersion ? data.webVersion : ''}"
    webVersionSourceCode: "${data.webVersionSourceCode ? data.webVersionSourceCode : ''}"
    webVersionRecording: "${data.webVersionRecording ? data.webVersionRecording : ''}"
    date: "${data.date}"
    acceptedDate: "${data.acceptedDate}"
    doi: "${data.doi}"
    teamIds: [${data.teams.join(',')}]
    authors: [${data.authors.join(',')}]
    visibility: ${data.visibility}
    hideFromPublic: ${data.hideFromPublic}
    dataset: ${data.dataset}
    datasetRDRStored: ${data.datasetRDRStored}
    chapterNr: "${data.chapterNr}"
  },
  firstAuthor: ${data.primaryAuthor},
  ) {
    id
    title
    webVersion
    webVersionSourceCode
    webVersionRecording
    attributes
    pages
    filetypes
    date
    acceptedDate
    doi
    status
    visibility
    hideFromPublic
    dataset
    datasetRDRStored
    chapterNr
    projects {
      id
      name
    }
    firstAuthor{
      id
      first
      last
    }
    authors{
      id
      first
      last
    }
    teams { id name }
    publication {
      id
      title
      date
    }
  }
}
`);

export const createBook = (data) => (`
mutation{
  createBook(book: {
    title: """${data.title}\n"""
    date: "${data.date}"
    acceptedDate: "${data.acceptedDate}"
    teamIds: [${data.teams.join(',')}]
    volume: "${data.volume}"
    status: "${data.status}"
    country: "${data.country}"
    publisher: "${data.publisher}"
    doi: "${data.doi}"
    pages: "${data.pages}"
    appeared: "${data.date}"
    authors: [${data.authors.join(',')}]
    editors: [${data.editors.join(',')}]
    webVersion: "${data.webVersion ? data.webVersion : ''}"
    webVersionSourceCode: "${data.webVersionSourceCode ? data.webVersionSourceCode : ''}"
    webVersionRecording: "${data.webVersionRecording ? data.webVersionRecording : ''}"
    isbn: "${data.isbn}"
    visibility: ${data.visibility}
    hideFromPublic: ${data.hideFromPublic}
    dataset: ${data.dataset}
    datasetRDRStored: ${data.datasetRDRStored}
  },
  firstAuthor: ${data.primaryAuthor},
  ){
    id
    title
    date
    acceptedDate
    appeared
    volume
    visibility
    hideFromPublic
    dataset
    datasetRDRStored
    series {
      id
      acronym
      name
      publisher
      issn
    }
    teams { id name }
    authors {
      id
      first
      last
      
    }
    editors {
      id
      first
      last
    }
    status
    pages
    doi
    country
    webVersion
    webVersionSourceCode
    webVersionRecording
    publisher
    isbn
    projects {
      id
      name
    }
  }
}
`);

export const createAbstractOfTalk = (data) => (`
mutation {
  createTalkArticle(
    article: {
      title: """${data.title}\n"""
      status: "${data.status}"
      attributes: "public,visible,cosic"
      filetypes: "pdf"
      webVersion: "${data.webVersion ? data.webVersion : ''}"
      webVersionSourceCode: "${data.webVersionSourceCode ? data.webVersionSourceCode : ''}"
      webVersionRecording: "${data.webVersionRecording ? data.webVersionRecording : ''}"
      authors: [${data.authors.join(',')}]
      doi: "${data.doi}"
      teamIds: [${data.teams.join(',')}]
      date: "${data.date}"
      acceptedDate: "${data.acceptedDate}"
      visibility: ${data.visibility}
      hideFromPublic: ${data.hideFromPublic}
      dataset: ${data.dataset}
      datasetRDRStored: ${data.datasetRDRStored}
      pages: "${data.pages}"
    }
    proceedingsId: ${data.proceedingsId},
    firstAuthor: ${data.primaryAuthor}
  ) {
    id
    title
    visibility
    hideFromPublic
    dataset
    datasetRDRStored
    pages
    teams { id name }
    doi
    webVersion
    webVersionSourceCode
    webVersionRecording
    date
    acceptedDate
    status
    timestamp
    projects { id name }
    firstAuthor { id first last }
    authors { id first last order }
    publication {
      id
      from
      to
      appeared
      edition
      volume
      city
      country
      state
      conference { id conference proceedings }
    }
  }
}
`);

export const createTalk = (data) => (`
mutation {
  createTalk(talk: {
    title: """${data.title}\n"""
    event: """${data.event}\n"""
    typeEvent: "${data.typeEvent}"
    from: "${data.date}"
    acceptedDate: "${data.acceptedDate}"
    city: "${data.city}"
    country: "${data.country}"
    attributes: "public,visible,international"
    filetypes: ""
    webVersion: "${data.webVersion ? data.webVersion : ''}"
    webVersionSourceCode: "${data.webVersionSourceCode ? data.webVersionSourceCode : ''}"
    webVersionRecording: "${data.webVersionRecording ? data.webVersionRecording : ''}"
    doi: "${data.doi}"
    teamIds: [${data.teams.join(',')}]
    status: "${data.status}"
    presenters: [${data.presenters.join(',')}]
    authors: [${data.authors.join(',')}]
    visibility: ${data.visibility}
    hideFromPublic: ${data.hideFromPublic}
    dataset: ${data.dataset}
    datasetRDRStored: ${data.datasetRDRStored}
  }
  firstAuthor: ${data.primaryAuthor},
  ){
    id
    title
    event
    typeEvent
    from
    acceptedDate
    city
    country
    attributes
    filetypes
    doi
    status
    webVersion
    webVersionSourceCode
    webVersionRecording
    visibility
    hideFromPublic
    dataset
    datasetRDRStored
    presenters{
      id
      first
      last
    }
    firstAuthor{
      id
      first
      last
    }
    authors{
      id
      first
      last
    }
    teams { id name }
    projects {
      id
      name
    }
  }
}
`);

export const updateTalk = (data) => (`
mutation {
  updateTalk(talk: {
    id: "${data.id}"
    title: """${data.title}\n"""
    event: """${data.event}\n"""
    typeEvent: "${data.typeEvent}"
    from: "${data.date}"
    acceptedDate: "${data.acceptedDate}"
    city: "${data.city}"
    country: "${data.country}"
    attributes: "public,visible,international"
    filetypes: ""
    liriaslink: "${data.liriaslink}"
    webVersion: "${data.webVersion ? data.webVersion : ''}"
    webVersionSourceCode: "${data.webVersionSourceCode ? data.webVersionSourceCode : ''}"
    webVersionRecording: "${data.webVersionRecording ? data.webVersionRecording : ''}"
    doi: "${data.doi}"
    teamIds: [${data.teams.join(',')}]
    status: "${data.status}"
    presenters: [${data.presenters.join(',')}]
    authors: [${data.authors.join(',')}]
    visibility: ${data.visibility}
    hideFromPublic: ${data.hideFromPublic}
    dataset: ${data.dataset}
    datasetRDRStored: ${data.datasetRDRStored}
  },
  firstAuthor: ${data.primaryAuthor},
  ){
    id
    title
    event
    typeEvent
    from
    acceptedDate
    city
    country
    attributes
    filetypes
    doi
    webVersion
    webVersionSourceCode
    webVersionRecording
    status
    visibility
    hideFromPublic
    dataset
    datasetRDRStored
    presenters{
      id
      first
      last
    }
    firstAuthor {
      id
      first
      last
    }
    authors{
      id
      first
      last
    }
    teams { id name }
    projects {
      id
      name
    }
  }
}
`);

export const updateAbstractOfTalk = (data) => (`
mutation{
  updateTalkArticle(article: {
    id: "${data.id}"
    title: """${data.title}\n"""
    teamIds: [${data.teams.join(',')}]
    attributes: "public,visible,cosic"
    status: "${data.status}"
    filetypes: "pdf"
    doi: "${data.doi}"
    date: "${data.date}"
    acceptedDate: "${data.acceptedDate}"
    authors: [${data.authors.join(',')}]
    webVersion: "${data.webVersion ? data.webVersion : ''}"
    webVersionSourceCode: "${data.webVersionSourceCode ? data.webVersionSourceCode : ''}"
    webVersionRecording: "${data.webVersionRecording ? data.webVersionRecording : ''}"
    visibility: ${data.visibility}
    hideFromPublic: ${data.hideFromPublic}
    dataset: ${data.dataset}
    datasetRDRStored: ${data.datasetRDRStored}
    pages: "${data.pages}"
  }
  proceedingsId: ${data.proceedingsId},
  firstAuthor: ${data.primaryAuthor},
  ){
    id
    title
    doi
    date
    acceptedDate
    webVersion
    webVersionSourceCode
    webVersionRecording
    status
    visibility
    hideFromPublic
    dataset
    datasetRDRStored
    pages
    teams { id name }
    firstAuthor { id first last }
    authors { id last first order }
    timestamp
    publication{
      id
      from
      to
      appeared
      edition
      volume
      city
      country
      state
      conference { id conference proceedings }
    }
    projects { id name }
  }
}
`);

export const createThese = (data) => (`
  mutation {
  createThese(these: {
    title: """${data.title}\n"""
    type: "${data.thesisType}"
    date: "${data.date}"
    acceptedDate: "${data.acceptedDate}"
    institution: "${data.institution}"
    pages: "${data.pages}"
    attributes: "public,visible,cosic"
    filetypes: ""
    webVersion: "${data.webVersion ? data.webVersion : ''}"
    webVersionSourceCode: "${data.webVersionSourceCode ? data.webVersionSourceCode : ''}"
    webVersionRecording: "${data.webVersionRecording ? data.webVersionRecording : ''}"
    doi: "${data.doi}"
    teamIds: [${data.teams.join(',')}]
    status: "${data.status}"
    country: "${data.country}"
    authors: [${data.authors.join(',')}]
    promotors: [${data.promotors.join(',')}]
    visibility: ${data.visibility}
    hideFromPublic: ${data.hideFromPublic}
    dataset: ${data.dataset}
    datasetRDRStored: ${data.datasetRDRStored}
  },
  firstAuthor: ${data.primaryAuthor},
  ){
    id
    title
    type
    date
    acceptedDate
    institution
    pages
    attributes
    filetypes
    owner
    doi
    webVersion
    webVersionSourceCode
    webVersionRecording
    status
    country
    visibility
    hideFromPublic
    dataset
    datasetRDRStored
    firstAuthor{
      id
      first
      last
    }
    authors{
      id
      first
      last
    }
    promotors{
      id
      first
      last
    }
    teams { id name }
    projects {
      id
      name
    }
  }
}
`);

export const createReport = (data) => (`
mutation {
  createReport(report: {
    title: """${data.title}\n"""
    pages: "${data.pages}"
    date: "${data.date}"
    acceptedDate: "${data.acceptedDate}"
    note: """${data.note}\n"""
    attributes: "public,visible,cosic"
    filetypes: ""
    doi: "${data.doi}"
    webVersion: "${data.webVersion ? data.webVersion : ''}"
    webVersionSourceCode: "${data.webVersionSourceCode ? data.webVersionSourceCode : ''}"
    webVersionRecording: "${data.webVersionRecording ? data.webVersionRecording : ''}"
    teamIds: [${data.teams.join(',')}]
    status: "${data.status}"
    country: "${data.country}"
    authors: [${data.authors.join(',')}]
    visibility: ${data.visibility}
    hideFromPublic: ${data.hideFromPublic}
    dataset: ${data.dataset}
    datasetRDRStored: ${data.datasetRDRStored}
  },
  firstAuthor: ${data.primaryAuthor},
  ) {
    id
    webVersion
    webVersionSourceCode
    webVersionRecording
    title
    type
    pages
    date
    acceptedDate
    note
    attributes
    status
    doi
    country
    visibility
    hideFromPublic
    dataset
    datasetRDRStored
    firstAuthor{
      id
      first
      last
    }
    authors{
      id
      first
      last
    }
    teams { id name }
    projects {
      id
      name
    }
  }
}
`);

export const createPatent = (data) => (`
mutation {
  createPatent(patent: {
    title: """${data.title}\n"""
    number: "${data.number}"
    description: """${data.description}\n"""
    date: "${data.date}"
    acceptedDate: "${data.acceptedDate}"
    applicant: "${data.applicant}"
    attributes: "public,submitted,visible,cosic"
    filetypes: ""
    webVersion: "${data.webVersion ? data.webVersion : ''}"
    webVersionSourceCode: "${data.webVersionSourceCode ? data.webVersionSourceCode : ''}"
    webVersionRecording: "${data.webVersionRecording ? data.webVersionRecording : ''}"
    doi: "${data.doi}"
    teamIds: [${data.teams.join(',')}]
    status: "${data.status}"
    authors: [${data.authors.join(',')}]
    visibility: ${data.visibility}
    hideFromPublic: ${data.hideFromPublic}
    dataset: ${data.dataset}
    datasetRDRStored: ${data.datasetRDRStored}
  },
  firstAuthor: ${data.primaryAuthor},
  ) {
    id
    title
    number
    date
    acceptedDate
    applicant
    attributes
    filetypes
    webVersion
    webVersionSourceCode
    webVersionRecording
    description
    owner
    timestamp
    status
    country
    doi
    visibility
    hideFromPublic
    dataset
    datasetRDRStored
    firstAuthor {
      id
      first
      last
    }
    authors {
      id
      first
      last
      
    }
    teams { id name }
    projects {
      id
      name
    }
  }
}
`);

export const updatePatent = (data) => (`
mutation {
  updatePatent(patent: {
    id: "${data.id}"
    title: """${data.title}\n"""
    number: "${data.number}"
    description: """${data.description}\n"""
    date: "${data.date}"
    acceptedDate: "${data.acceptedDate}"
    applicant: "${data.applicant}"
    attributes: "public,submitted,visible,cosic"
    filetypes: ""
    webVersion: "${data.webVersion ? data.webVersion : ''}"
    webVersionSourceCode: "${data.webVersionSourceCode ? data.webVersionSourceCode : ''}"
    webVersionRecording: "${data.webVersionRecording ? data.webVersionRecording : ''}"
    doi: "${data.doi}"
    teamIds: [${data.teams.join(',')}]
    status: "${data.status}"
    country: "${data.country}"
    authors: [${data.authors.join(',')}]
    visibility: ${data.visibility}
    hideFromPublic: ${data.hideFromPublic}
    dataset: ${data.dataset}
    datasetRDRStored: ${data.datasetRDRStored}
  },
  firstAuthor: ${data.primaryAuthor},
  ) {
    id
    title
    number
    date
    acceptedDate
    applicant
    attributes
    filetypes
    webVersion
    webVersionSourceCode
    webVersionRecording
    owner
    timestamp
    status
    country
    doi
    description
    visibility
    hideFromPublic
    dataset
    datasetRDRStored
    firstAuthor{
      id
      first
      last
    }
    authors {
      id
      first
      last
      
    }
    teams { id name }
    projects {
      id
      name
    }
  }
}
`);

export const updateReport = (data) => (`
mutation {
  updateReport(report: {
    id: "${data.id}"
    title: """${data.title}\n"""
    pages: "${data.pages}"
    date: "${data.date}"
    acceptedDate: "${data.acceptedDate}"
    note: """${data.note}\n"""
    attributes: "public,visible,cosic"
    filetypes: ""
    doi: "${data.doi}"
    webVersion: "${data.webVersion ? data.webVersion : ''}"
    webVersionSourceCode: "${data.webVersionSourceCode ? data.webVersionSourceCode : ''}"
    webVersionRecording: "${data.webVersionRecording ? data.webVersionRecording : ''}"
    teamIds: [${data.teams.join(',')}]
    status: "${data.status}"
    country: "${data.country}"
    authors: [${data.authors.join(',')}]
    visibility: ${data.visibility}
    hideFromPublic: ${data.hideFromPublic}
    dataset: ${data.dataset}
    datasetRDRStored: ${data.datasetRDRStored}
  },
  firstAuthor: ${data.primaryAuthor},
  ) {
    id
    webVersion
    webVersionSourceCode
    webVersionRecording
    title
    type
    pages
    date
    acceptedDate
    note
    attributes
    status
    doi
    country
    visibility
    hideFromPublic
    dataset
    datasetRDRStored
    firstAuthor{
      id
      first
      last
    }
    authors{
      id
      first
      last
    }
    teams { id name }
    projects {
      id
      name
    }
  }
}
`);

export const updateBookArticle = (data) => (`
mutation{
  updateBookArticle(input: {
    id: "${data.id}"
    title: """${data.title}\n"""
    publication: ${data.book}
    status: "${data.status}"
    pages: "${data.pages}"
    chapterNr: "${data.chapterNr}"
    attributes: "public,visible,cosic"
    filetypes: ""
    webVersion: "${data.webVersion ? data.webVersion : ''}"
    webVersionSourceCode: "${data.webVersionSourceCode ? data.webVersionSourceCode : ''}"
    webVersionRecording: "${data.webVersionRecording ? data.webVersionRecording : ''}"
    date: "${data.date}"
    acceptedDate: "${data.acceptedDate}"
    doi: "${data.doi}"
    teamIds: [${data.teams.join(',')}]
    authors: [${data.authors.join(',')}]
    visibility: ${data.visibility}
    hideFromPublic: ${data.hideFromPublic}
    dataset: ${data.dataset}
    datasetRDRStored: ${data.datasetRDRStored}
  },
  firstAuthor: ${data.primaryAuthor},
  ) {
    id
    title
    webVersion
    webVersionSourceCode
    webVersionRecording
    attributes
    pages
    chapterNr
    filetypes
    date
    acceptedDate
    doi
    status
    visibility
    hideFromPublic
    dataset
    datasetRDRStored
    firstAuthor{
      id
      first
      last
    }
    authors{
      id
      first
      last
    }
    teams { id name }
    publication {
      id
      title
      date
    }
    projects {
      id
      name
    }
  }
}
`);

export const updateThese = (data) => (`
  mutation {
  updateThese(these: {
    id: "${data.id}"
    title: """${data.title}\n"""
    type: "${data.thesisType}"
    date: "${data.date}"
    acceptedDate: "${data.acceptedDate}"
    institution: "${data.institution}"
    pages: "${data.pages}"
    attributes: "public,visible,cosic"
    filetypes: ""
    webVersion: "${data.webVersion ? data.webVersion : ''}"
    webVersionSourceCode: "${data.webVersionSourceCode ? data.webVersionSourceCode : ''}"
    webVersionRecording: "${data.webVersionRecording ? data.webVersionRecording : ''}"
    doi: "${data.doi}"
    teamIds: [${data.teams.join(',')}]
    status: "${data.status}"
    country: "${data.country}"
    authors: [${data.authors.join(',')}]
    promotors: [${data.promotors.join(',')}]
    visibility: ${data.visibility}
    hideFromPublic: ${data.hideFromPublic}
    dataset: ${data.dataset}
    datasetRDRStored: ${data.datasetRDRStored}
  },
  firstAuthor: ${data.primaryAuthor},
  ){
    id
    title
    type
    date
    acceptedDate
    institution
    pages
    attributes
    filetypes
    owner
    doi
    webVersion
    webVersionSourceCode
    webVersionRecording
    status
    country
    visibility
    hideFromPublic
    dataset
    datasetRDRStored
    firstAuthor{
      id
      first
      last
    }
    authors{
      id
      first
      last
    }
    promotors{
      id
      first
      last
    }
    teams { id name }
    projects {
      id
      name
    }
  }
}
`);

export const QuerytheseById = (id) => (`{
  theseById(id: ${id}){
    id
    title
    type
    date
    acceptedDate
    institution
    pages
    attributes
    filetypes
    owner
    doi
    webVersion
    webVersionSourceCode
    webVersionRecording
    status
    country
    visibility
    hideFromPublic
    dataset
    datasetRDRStored
    firstAuthor{
      id
      first
      last
    }
    authors{
      id
      first
      last
    }
    promotors{
      id
      first
      last
    }
    teams { id name }
    projects {
      id
      name
      acronym
    }
  }
}
`);

export const QuerytalkById = (id) => (`{
  talkById(id: ${id}) {
    id
    title
    event
    from
    acceptedDate
    city
    country
    attributes
    filetypes
    typeEvent
    doi
    status
    webVersion
    webVersionSourceCode
    webVersionRecording
    visibility
    hideFromPublic
    dataset
    datasetRDRStored
    presenters{
      id
      first
      last
    }
    firstAuthor{
      id
      first
      last
    }
    authors{
      id
      first
      last
    }
    teams { id name }
    projects {
      id
      name
      acronym
    }
  }
}
`);

export const QuerytalkArticleById = (id) => (`{
  talkArticleById(id: ${id}) {
    id
    title
    doi
    date
    acceptedDate
    webVersion
    webVersionSourceCode
    webVersionRecording
    visibility
    hideFromPublic
    dataset
    datasetRDRStored
    status
    pages
    teams { id name }
    firstAuthor{
      id
      first
      last
    }
    authors {
      id
      last
      first
      order
    }
    timestamp
    publication {
      id
      from
      to
      appeared
      edition
      volume
      city
      country
      state
      conference {
        id
        conference
        proceedings
      }
    }
    projects {
      id
      name
      acronym
    }
  }
}
`);

export const QueryjournalArticleById = (id) => (`
{
  journalArticleById(id: ${id})
  ${journalReturnObject}
}
`);

export const QueryconferenceArticleById = (id) => (`{
  conferenceArticleById(id: ${id}) {
      id
      title
      proceedingTitle
      presentedAtRump
      complete
      reviewed
      publisher
      visibility
      hideFromPublic
      dataset
      datasetRDRStored
      earlyAccess
      editors {
        id
        first
        last
        unumber
      }
      teams { id name }
      doi
      series
      webVersion
      webVersionSourceCode
      webVersionRecording
      date
      acceptedDate
      pages
      status
      firstAuthor {
        id
        first
        last
      }
      authors {
        id
        first
        last
        order
      }
      publication {
        id
        from
        to
        appeared
        edition
        volume
        city
        country
        state
        conference {
          id
          conference
          proceedings
        }
      }
      projects {
        id
        name
        acronym
      }
    }
  }
`);

export const QueryreportById = (id) => (`{
  reportById(id: ${id}) {
    id
    webVersion
    webVersionSourceCode
    webVersionRecording
    title
    type
    pages
    date
    acceptedDate
    note
    attributes
    status
    doi
    country
    visibility
    hideFromPublic
    dataset
    datasetRDRStored
    firstAuthor {
      id
      first
      last
    }
    authors{
      id
      first
      last
      order
    }
    teams { id name }
    projects {
      id
      name
      acronym
    }
  }
}
`);

export const QueryproceedingById = (id) => (`{
  proceedingById(id: ${id}) {
    id
    edition
    volume
    appeared
    acceptedDate
    visibility
    hideFromPublic
    dataset
    datasetRDRStored
    title
    publisher
    from
    to
    editors {
      id
      first
      last
      unumber
    }
    country
    city
    doi
    state
    url
    webVersion
    webVersionSourceCode
    webVersionRecording
    status
    teams { id name }
    conference {
      id
      acronym
      conference
      proceedings
      category
      booktitle
      series {
        id
        acronym
        name
        publisher
        issn
        link
        isiimpact
        aciimpact
        owner
        timestamp
      }
      publisher
      attributes
      isiimpact
      aciimpact
      owner
      timestamp
    }
    projects {
      id
      name
      acronym
    }
  }
}
`);

export const QuerybookArticleById = (id) => (`{
  bookArticleById(id: ${id} ) {
    id
    title
    webVersion
    webVersionSourceCode
    webVersionRecording
    attributes
    pages
    filetypes
    date
    acceptedDate
    doi
    status
    visibility
    hideFromPublic
    dataset
    datasetRDRStored
    firstAuthor{
      id
      first
      last
    }
    authors{
      id
      first
      last
      
    }
    teams { id name }
    publication {
      id
      title
      date
    }
    projects {
      id
      name
      acronym
    }
    chapterNr
  }
}
`);

export const QuerypatentById = (id) => (`{
  patentById(id: ${id}) {
    id
    title
    number
    date
    acceptedDate
    applicant
    attributes
    filetypes
    webVersion
    webVersionSourceCode
    webVersionRecording
    owner
    timestamp
    status
    country
    doi
    description
    visibility
    hideFromPublic
    dataset
    datasetRDRStored
    firstAuthor{
      id
      first
      last
    }
    authors {
      id
      first
      last
      
    }
    teams { id name }
    projects {
      id
      name
      acronym
    }
  }
}
`);

export const QuerybookById = (id) => (`{
  bookById(id: ${id}) {
    id
    title
    date
    acceptedDate
    appeared
    volume
    visibility
    hideFromPublic
    dataset
    datasetRDRStored
    teams { id name }
    firstAuthor{
      id
      first
      last
    }
    authors {
      id
      first
      last
      
    }
    editors {
      id
      first
      last
    }
    status
    pages
    doi
    country
    webVersion
    webVersionSourceCode
    webVersionRecording
    publisher
    isbn
    projects {
      id
      name
      acronym
    }
  }
}
`);

export const QueryVolumes = (`{
  volumes {
    id
    journal {
      title
    }
    appeared
  }
}`
);

export const QueryTeams = (`{
  teams { id name }
}`
);

export const QueryProceedings = (`
{
  rawProceedings {
    id
    from
    conference {
      id
      proceedings
      timestamp
    }
  }
  conferences{
    id
    conference
    proceedings
    acronym
      series{
        id
      }
  }
  series{
    id
    acronym
    name
    publisher
    issn
  }
  allBooks {
    id
    title
  }
  journals{
    id
    title
    acronym
  }
  institution
  theseTitle
  talkTitle
  reportTitle
  patentTitle
  journalArticleTitle
  conferenceArticleTitle
  bookTitle
  bookArticleTitle
  abstractOfTalkTitle
}
`);

export const DeletePublication = (id, type) => (`
mutation{
  deletePublication(type: "${type}", id:${id} )
}
`);

export const linkProjectWithPublication = (publicationId, projectId, publicationType) => (`
mutation{
  createProjectPublication(projectPublication: {
    projectId: ${projectId}
    publicationId: ${publicationId}
    type: "${publicationType}"
  }) {
    id
    projectId
    publicationId
    type
  }
}
`);

export const unlinkProjectFromPublication = (publicationId, projectId, publicationType) => (`
mutation{
  deleteProjectPublication(
    projectId: ${projectId}
    publicationId: ${publicationId}
    type: "${publicationType}"
  )
}
`);

export default {
  QueryALlYearsAndPeople,
  QueryAllPublications,
};
