import React, { useEffect, useState } from 'react';
import { merge } from 'lodash/fp';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useParams } from 'react-router-dom';
import { Formik, ErrorMessage } from 'formik';
import Select from 'react-select';
import {
  Col,
  Button,
  Form,
  Card,
  Container,
  Row,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import Tabs from '../Tabs';
import { bookchapterValidationSchema as validationSchema } from '../ValidationSchema';
import { Loader } from '../../../../component';
import GeneralDataForm from '../GeneralDataForm';
import {
  createBookchapter as createBookchapterAction,
  loadBookchapterRequirements as loadBookchapterRequirementsAction,
  updateBookchapter as updateBookchapterAction,
} from './actions';
import {
  selectIsLoading,
  selectProjects,
  selectPublicationBookchapterDetails,
} from './selectors';
import { selectBooks } from '../../../../state/publication/selectors';
import { calculatePageCount } from '../../../../utils';

const Bookchapter = ({
  books,
  createBookchapter,
  isLoading,
  isResearcher,
  loadBookchapterRequirements,
  bookchapterInfo,
  projects,
  updateBookchapter,
}) => {
  const { id } = useParams();
  const [reloads, setReloads] = useState(0);

  useEffect(() => {
    loadBookchapterRequirements({ id, reset: true });
  }, [id, loadBookchapterRequirements]);

  useEffect(() => {
    if (reloads > 0) {
      loadBookchapterRequirements({ reset: false });
    }
  }, [reloads, loadBookchapterRequirements]);

  const initialFormValues = merge({
    types: 'bookchapter',
    title: '',
    status: null,
    teams: [],
    primaryAuthor: null,
    authors: [],
    date: '',
    acceptedDate: '',
    doi: '',
    webVersion: '',
    webVersionSourceCode: '',
    webVersionRecording: '',
    visibility: false,
    hideFromPublic: false,
    dataset: null,
    datasetRDRStored: null,
    book: null,
    pages: '',
    pageCount: '',
    chapterNr: '',
  }, bookchapterInfo);

  const handleBookchapterSave = (values, { setSubmitting }) => {
    const updatedValues = {
      ...values,
      authors: values.authors.map((author) => author.value),
      teams: values.teams.map((team) => team.value),
      type: 'bookchapter',
    };

    if (id) {
      updateBookchapter({ ...updatedValues, id });
    } else {
      createBookchapter(updatedValues);
    }
    setSubmitting(false);
  };

  const handlePageChange = (event, setFieldValue) => {
    const { value } = event.target;
    setFieldValue('pages', value);
    setFieldValue('pageCount', calculatePageCount(value));
  };

  return (
    <Container fluid>
      <Row>
        <Col>
          {!isLoading ? (
            <Formik
              enableReinitialize
              initialValues={initialFormValues}
              validationSchema={validationSchema}
              onSubmit={handleBookchapterSave}
            >
              {({
                errors,
                handleChange,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                touched,
                values,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <GeneralDataForm
                    errors={errors}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    touched={touched}
                    values={values}
                    type="bookchapter"
                    id={id}
                    reload={() => setReloads(reloads + 1)}
                  />
                  <Card className="filter-card">
                    <Card.Header>Bookchapter specific</Card.Header>
                    <Card.Body>
                      <Row style={{ marginTop: '2rem' }}>
                        <Col sm={2}>
                          <Form.Label>Page(s)</Form.Label>
                          <Form.Control
                            name="pages"
                            onChange={(event) => handlePageChange(event, setFieldValue)}
                            value={values.pages}
                            className={touched.pages && errors.pages ? 'error' : null}
                          />
                          <ErrorMessage name="pages" />
                        </Col>
                        <Col sm={2}>
                          <Form.Label>Page Count</Form.Label>
                          <Form.Control
                            disabled
                            placeholder="Fill pages..."
                            value={values.pageCount}
                            className={values.pageCount && !parseInt(values.pageCount, 10) ? 'error' : null}
                          />
                          <ErrorMessage name="pageCount" />
                        </Col>
                        <Col sm={2}>
                          <Form.Label>Chapter Nr</Form.Label>
                          <Form.Control
                            name="chapterNr"
                            onChange={handleChange}
                            value={values.chapterNr}
                            className={touched.chapterNr && errors.chapterNr ? 'error' : null}
                          />
                          <ErrorMessage name="chapterNr" />
                        </Col>
                      </Row>
                      <Row style={{ marginTop: '2rem' }}>
                        <Col>
                          <Form.Label>Book</Form.Label>
                          <Form.Text muted>
                            Missing book? Add via new publication -> new book
                          </Form.Text>
                          <Select
                            name="book"
                            onChange={(s) => setFieldValue('book', s.value)}
                            options={books}
                            value={values.book ? (books.find((book) => book.value === values.book)) : null}
                            className={touched.book && errors.book ? 'error' : null}
                          />
                          <div className="error-message">
                            <ErrorMessage name="country" />
                          </div>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                  <Row style={{ marginTop: '1em', marginBottom: '1em' }}>
                    <Button
                      type="submit"
                      variant="primary"
                      className="btn btn-success ml-auto"
                      disabled={isSubmitting}
                      block
                    >
                      {id ? 'Update' : 'Create'}
                    </Button>
                  </Row>
                </Form>
              )}
            </Formik>
          ) : (
            <Loader />
          )}
        </Col>
      </Row>
      {id && (
        <Tabs
          id={id}
          type="bookchapter"
          isResearcher={isResearcher}
          projects={projects}
          pdfVisibility={bookchapterInfo.visibility}
        />
      )}
    </Container>
  );
};

Bookchapter.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  books: PropTypes.arrayOf(PropTypes.object).isRequired,
  createBookchapter: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isResearcher: PropTypes.bool.isRequired,
  loadBookchapterRequirements: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  bookchapterInfo: PropTypes.objectOf(PropTypes.any).isRequired,
  updateBookchapter: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  projects: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const mapStateToProps = createStructuredSelector({
  books: selectBooks,
  isLoading: selectIsLoading,
  bookchapterInfo: selectPublicationBookchapterDetails,
  projects: selectProjects,
});

const mapDispatchToProps = {
  createBookchapter: createBookchapterAction,
  loadBookchapterRequirements: loadBookchapterRequirementsAction,
  updateBookchapter: updateBookchapterAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Bookchapter);
