import { put, call, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { Store as store } from 'react-notifications-component';
import axios from '../../axios';
import {
  QueryOneConference,
  QueryOneJournal,
  MutateCreateConference,
  MutateCreateJournal,
  MutateUpdateConference,
  MutateUpdateJournal,
} from '../../graphql/queries/venues';
import {
  FETCH_ONE_VENUE,
  fetchOneVenueSuccess,
  fetchOneVenueError,
  UPDATE_VENUE,
  updateVenueSuccess,
  updateVenueError,
} from './venueActions';
import catchErrors from '../../graphql/sagas';

export function* getOneVenue(action) {
  let response;
  if (action.data.type === 'journal') {
    response = yield call(axios.post, '/', { query: QueryOneJournal(action.data.id) });
    yield put(fetchOneVenueSuccess(response.data.data.journalById));
  } else if (action.data.type === 'conference') {
    response = yield call(axios.post, '/', { query: QueryOneConference(action.data.id) });
    yield put(fetchOneVenueSuccess(response.data.data.conferenceById));
  }
}

export function* updateVenue(action) {
  const { type, id } = action.data;
  let query;
  if (type === 'journal') {
    if (!id) {
      query = MutateCreateJournal(action.data);
    } else {
      query = MutateUpdateJournal(action.data);
    }
  } else if (type === 'conference') {
    if (!id) {
      query = MutateCreateConference(action.data);
    } else {
      query = MutateUpdateConference(action.data);
    }
  }
  const response = yield call(axios.post, '/', { query });
  yield put(updateVenueSuccess(response.data));
  if (!id) {
    const newId = response.data.data.createJournal ? (
      response.data.data.createJournal.id
    ) : response.data.data.createConferenceInput.id;
    yield put(push(`/admin/settings/venues/${type}/${newId}`));
  }
  store.addNotification({
    title: 'SUCCESS!',
    message: 'Update successfully saved!',
    type: 'success',
    insert: 'top',
    container: 'top-right',
    dismiss: {
      duration: 5000,
      onScreen: true,
    },
  });
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(
    FETCH_ONE_VENUE,
    catchErrors({
      onErrorAction: fetchOneVenueError,
      overrideErrorMessage: 'Unable to retrieve venue',
    })(getOneVenue),
  );

  yield takeLatest(
    UPDATE_VENUE,
    catchErrors({
      onErrorAction: updateVenueError,
      overrideErrorMessage: 'Unable to update venue',
    })(updateVenue),
  );
}
