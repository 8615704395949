import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';
import { connect } from 'react-redux';
import {
  authorNameFormatter, authorNameFormatterText, getYearFromDate, idFormatter, statusFormatter,
} from './typeHelper';
import { ClickText } from '..';
import {
  findPdf,
  pagesView,
  pdfIcon,
  shouldShowPublication,
  titleView,
  webVersionIcon,
  webVersionRecordingIcon,
} from '../../utils';
import EditButton from '../EditButton';

function Thesis(props) {
  const [isBibTexShown, showBibTex] = useState(false);
  const [isLaTexShown, showLaTex] = useState(false);
  const {
    id, title, status, canEdit, pages, promotors, institution, index, type, authors, date, webVersion, webVersionRecording, visibility, files, isAuthenticated,
  } = props;

  const location = useLocation();
  const isAdmin = location.pathname.includes('admin');
  const pdfFile = findPdf(files);
  const containsPdf = pdfFile !== undefined;
  const showPublication = shouldShowPublication(isAdmin, containsPdf, visibility);

  const handleBTClick = () => {
    showLaTex(false);
    showBibTex(!isBibTexShown);
  };
  const handleLTClick = () => {
    showBibTex(false);
    showLaTex(!isLaTexShown);
  };
  const note = promotors ? promotors.map((a) => `${a.first} ${a.last}`).join(' and\n\t\t ').concat(' (promotor)') : '';
  const bibTex = (`
    @PHDTHESIS{thesis${id},
      author =    {${authors ? authors.map((a) => `${a.first} ${a.last}`).join(' and\n\t\t ') : ''}},
      title =     {${title}},
      school =    {${institution}},
      note =      {${note}},
      year =      {${date ? getYearFromDate(date) : ''}},
    }
  `);
  const laTex = (`
    \\item ${authorNameFormatterText(authors)}, \`\`${title},'' ${_.capitalize(type)} thesis, { ${institution}}${note || ''}, pp.~${pages || ''}, ${getYearFromDate(date)}
  `);

  return (
    <tr>
      <td>{index}</td>
      <td>
        <table>
          <tbody>
            <tr>
              <td>
                {statusFormatter(status)}
                {isAuthenticated && `${idFormatter(id)} `}
                {authorNameFormatter(authors, true)}
                ,
                &quot;
                {titleView(id, title, visibility, pdfFile, showPublication, 'these', isAdmin)}
                &quot;,
                {' '}
                {_.capitalize(type)}
                {' '}
                thesis,
                {' '}
                {institution}
                {promotors?.length > 0 ? ', ' : ''}
                {authorNameFormatter(promotors, true)}
                {pagesView(pages)}
                {date ? `, ${getYearFromDate(date)}` : ''}
                .
                {' '}
                {showPublication && containsPdf
                  ? pdfIcon(id, pdfFile, 'these')
                  : null}
                {webVersionRecordingIcon(webVersionRecording)}
                {webVersionIcon(webVersion)}
                <EditButton canEdit={canEdit} to={`/researcher/publications/updateThese/${id}`} />
              </td>
              <td>
                <ClickText text="BibTex" onClick={handleBTClick} />
                {' '}
                <ClickText text="LaTex" onClick={handleLTClick} />
              </td>
              {isBibTexShown ? (
                <td style={{ border: '1px solid black' }}>
                  <pre style={{ whiteSpace: 'pre' }}>
                    {bibTex}
                  </pre>
                </td>
              ) : null}
              {isLaTexShown ? (
                <td style={{ border: '1px solid black' }}>
                  <pre style={{ whiteSpace: 'pre-line' }}>
                    {laTex}
                  </pre>
                </td>
              ) : null}
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
  );
}
Thesis.propTypes = {
  id: PropTypes.string.isRequired,
  date: PropTypes.string,
  title: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  pages: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  promotors: PropTypes.arrayOf(PropTypes.any).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  authors: PropTypes.arrayOf(PropTypes.any).isRequired,
  institution: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  webVersion: PropTypes.string,
  webVersionRecording: PropTypes.string,
  visibility: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  files: PropTypes.arrayOf(PropTypes.any).isRequired,
  canEdit: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
};

Thesis.defaultProps = {
  date: '',
  webVersion: '',
  webVersionRecording: '',
  visibility: false,
};
const mapStateToProps = (state) => ({
  isAuthenticated: state.user.isAuthenticated,
});
export default connect(mapStateToProps)(Thesis);
