import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { startCase, capitalize } from 'lodash/fp';
import {
  Card,
  Col,
  Button,
  Form, Row,
} from 'react-bootstrap';
import Select from 'react-select';
import Datetime from 'react-datetime';
import { ErrorMessage } from 'formik';
import {
  selectPublicationAuthors,
  selectPublicationTeamsAsOptions,
} from '../../../state/publication/selectors';
import {
  deletePublication as deletePublicationAction,
} from '../../../state/publication/publicationActions';
import { publicationStatusOptions, yesNoOptions } from '../../../config/optionValues';
import CreateExternalPeopleModal from '../../../component/admin/people/createExternalPeopleModal';
import DeleteConfirmation from '../../../component/admin/settings/deleteConfirmation';
import { customFilterMatchAll } from '../../../component/publicationFormatTypes/typeHelper';

export const datetimeWorkaround = (value) => (value === '' ? { value: '' } : {});
const GeneralDataForm = ({
  authors,
  deletePublication,
  errors,
  handleChange,
  id,
  setFieldValue,
  teams,
  touched,
  type,
  values,
  reload,
}) => {
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const handleDatetimeChange = (dt, fieldName, format) => {
    if (typeof (dt) === 'string') {
      setFieldValue(fieldName, dt);
    } else {
      setFieldValue(fieldName, dt.format(format));
    }
  };

  const constructDeleteMessage = () => {
    const publicationTitle = values.title ? `"${values.title}"` : '';
    let publicationType = '';

    switch (type) {
      case 'abstractOfTalk':
        publicationType = 'Abstract of Talk';
        break;
      case 'conferencePaper':
        publicationType = 'Conference Paper';
        break;
      default:
        publicationType = startCase(capitalize(type));
    }

    return `Delete ${publicationType} publication ${publicationTitle}`;
  };

  return (
    <>
      <Card className="filter-card">
        <Card.Header>
          General Data
          {id && (
            <Button
              size="sm"
              onClick={() => setShowDeleteConfirmation(true)}
              variant="danger"
              style={{
                fontSize: '.8em',
                padding: '.0rem .4rem',
                float: 'right',
              }}
            >
              Delete publication
            </Button>
          )}

          <Button
            variant="secundary"
            className="btn btn-success ml-auto"
            style={{ width: 200, float: 'right' }}
            onClick={reload}
          >
            Reload Options
          </Button>

        </Card.Header>
        <Card.Body>
          <Row>
            <Col sm={6}>
              <Form.Label>Title</Form.Label>
              <Form.Text muted>
                The title in plain text, e.g.: Dolphin: A Cellular Voice Based Internet Shutdown Resistance System
              </Form.Text>
              <Form.Control
                name="title"
                onChange={handleChange}
                className={touched.title && errors.title ? 'error' : null}
                value={values.title}
              // disabled={type === 'proceeding'}
              />
              <div className="error-message">
                <ErrorMessage name="title" />
              </div>
            </Col>
            <Col>
              <Form.Label>Status</Form.Label>
              <Select
                name="status"
                onChange={(event) => setFieldValue('status', event.value)}
                options={publicationStatusOptions}
                className={touched.status && errors.status ? 'error' : null}
                value={publicationStatusOptions && values.status ? (
                  publicationStatusOptions.find((status) => status.value === values.status)
                ) : null}
              />
              <div className="error-message">
                <ErrorMessage name="status" />
              </div>
            </Col>
            <Col>
              <Form.Label>Team(s)</Form.Label>
              <Select
                name="teams"
                isMulti
                onChange={(value) => setFieldValue('teams', value)}
                className={touched.teams && errors.teams ? 'error' : null}
                options={teams}
                value={values.teams}
              />
              <div className="error-message">
                <ErrorMessage name="team" />
              </div>
            </Col>
          </Row>

          <Row style={{ marginTop: '2rem' }}>
            <Col>
              <Form.Label>
                Primary Author
                {' '}
                <CreateExternalPeopleModal />
              </Form.Label>
              <Select
                name="primaryAuthor"
                className={touched.primaryAuthor && errors.primaryAuthor ? 'error' : null}
                onChange={(event) => setFieldValue('primaryAuthor', event.value)}
                options={authors}
                filterOption={customFilterMatchAll}
                value={authors && values.primaryAuthor ? (
                  authors.find((author) => author.value === values.primaryAuthor)
                ) : ''}
                isDisabled={type === 'proceeding'}
              />
              <div className="error-message">
                <ErrorMessage name="primaryAuthor" />
              </div>
            </Col>
            <Col sm={5}>
              <Form.Label>
                Co-Authors
                {' '}
                <CreateExternalPeopleModal />
              </Form.Label>
              <Form.Text muted>
                The authors will appear in the order you add them here
              </Form.Text>
              <Select
                name="authors"
                isMulti
                onChange={(value) => setFieldValue('authors', value || [])}
                options={authors}
                filterOption={customFilterMatchAll}
                value={values.authors ? values.authors.sort((a, b) => a.order - b.order) : null}
                isDisabled={type === 'proceeding'}
              />
            </Col>
          </Row>

          <Row style={{ marginTop: '2rem' }}>
            <Col>
              <Form.Label>Publishing Date</Form.Label>
              <Datetime
                className={touched.date && errors.date ? 'error' : null}
                dateFormat="YYYY-MM-DD"
                name="date"
                onChange={(date) => handleDatetimeChange(date, 'date', 'YYYY-MM-DD')}
                timeFormat={false}
                value={values.date}
                inputProps={{ // https://github.com/arqex/react-datetime/issues/760#issuecomment-1210475819
                  ...datetimeWorkaround(values.date),
                }}
              />
              <div className="error-message">
                <ErrorMessage name="date" />
              </div>
            </Col>
            <Col>
              <Form.Label>Accepted Date</Form.Label>
              <Datetime
                className={touched.acceptedDate && errors.acceptedDate ? 'error' : null}
                dateFormat="YYYY-MM-DD"
                name="acceptedDate"
                onChange={(date) => handleDatetimeChange(date, 'acceptedDate', 'YYYY-MM-DD')}
                timeFormat={false}
                value={values.acceptedDate}
                inputProps={{ // https://github.com/arqex/react-datetime/issues/760#issuecomment-1210475819
                  ...datetimeWorkaround(values.acceptedDate),
                }}
              />
              <div className="error-message">
                <ErrorMessage name="acceptedDate" />
              </div>
            </Col>
            <Col>
              <Form.Label>DOI</Form.Label>
              <Form.Text muted>
                Format: https://doi.org/10.1007/s00145-022-09435-1
              </Form.Text>
              <Form.Control
                name="doi"
                className={touched.doi && errors.doi ? 'error' : null}
                value={values.doi}
                onChange={handleChange}
              />
              <div className="error-message">
                <ErrorMessage name="doi" />
              </div>
            </Col>

          </Row>

          <Row style={{ marginTop: '1rem' }}>
            <Col>
              <Form.Label>Web Version</Form.Label>
              <Form.Text muted>
                e.g. https://link.springer.com/article/10.1007/s00145-022-09435-1
              </Form.Text>
              <Form.Control
                name="webVersion"
                className={touched.webVersion && errors.webVersion ? 'error' : null}
                value={values.webVersion}
                onChange={handleChange}
              />
              <div className="error-message">
                <ErrorMessage name="webVersion" />
              </div>
            </Col>
            <Col>
              <Form.Label>Link to dataset/source code on COSIC github</Form.Label>
              <Form.Text muted>
                e.g. https://github.com/KULeuven-COSIC/SCALE-MAMBA
              </Form.Text>
              <Form.Control
                name="webVersionSourceCode"
                className={touched.webVersionSourceCode && errors.webVersionSourceCode ? 'error' : null}
                value={values.webVersionSourceCode}
                onChange={handleChange}
              />
              <div className="error-message">
                <ErrorMessage name="webVersionSourceCode" />
              </div>
            </Col>
            <Col>
              <Form.Label>Link to recording</Form.Label>
              <Form.Text muted>
                e.g. https://www.youtube.com/watch?v=clrNuBb3myE
              </Form.Text>
              <Form.Control
                name="webVersionRecording"
                className={touched.webVersionRecording && errors.webVersionRecording ? 'error' : null}
                value={values.webVersionRecording}
                onChange={handleChange}
              />
              <div className="error-message">
                <ErrorMessage name="webVersionRecording" />
              </div>
            </Col>
          </Row>

          <Row style={{ marginTop: '1rem' }}>
            <Col>
              <Form.Group controlId="visibility">
                <Form.Check
                  label="Make PDF publicly visible"
                  name="visibility"
                  onChange={(event) => setFieldValue('visibility', event.target.checked)}
                  checked={values.visibility}
                />
                <Form.Text muted>
                  Check this box if you want to SHOW the PDF file in the public publications list
                </Form.Text>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="hideFromPublic">
                <Form.Check
                  label="Hide publication (in public list)"
                  name="hideFromPublic"
                  onChange={(event) => setFieldValue('hideFromPublic', event.target.checked)}
                  checked={values.hideFromPublic}
                />
                <Form.Text muted>
                  Check this box if you want to hide this record from the public publications list
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>
          <Row style={{ marginTop: '1rem' }}>
            <Col sm={2}>
              <Form.Group controlId="dataset">
                <Form.Label>Does this publication have a dataset?</Form.Label>
                <Select
                  name="dataset"
                  onChange={(event) => {
                    setFieldValue('dataset', event.value);
                  }}
                  options={yesNoOptions}
                  className={touched.dataset && errors.dataset ? 'error' : null}
                  value={
                    yesNoOptions.find((o) => (o.value === 'true') === values.dataset)
                  }
                />
                <Form.Text muted>
                  A dataset contains all final data needed to reproduce the research results
                </Form.Text>
                <div className="error-message">
                  <ErrorMessage name="dataset" />
                </div>
              </Form.Group>
            </Col>
            <Col sm={2}>
              <Form.Group controlId="datasetRDRStored">
                <Form.Label>Is the dataset stored in RDR?</Form.Label>
                <Select
                  name="datasetRDRStored"
                  onChange={(event) => {
                    setFieldValue('datasetRDRStored', event.value);
                  }}
                  options={yesNoOptions}
                  className={touched.datasetRDRStored && errors.datasetRDRStored ? 'error' : null}
                  value={
                    yesNoOptions.find((o) => (o.value === 'true') === values.datasetRDRStored)
                  }
                />
                <Form.Text muted>
                  Dana will contact you and add your dataset to RDR soon, you can select no for now.
                </Form.Text>
                <div className="error-message">
                  <ErrorMessage name="datasetRDRStored" />
                </div>
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <DeleteConfirmation
        text={constructDeleteMessage()}
        show={showDeleteConfirmation}
        onCancel={() => setShowDeleteConfirmation(false)}
        onConfirm={() => deletePublication({ id, type })}
      />
    </>
  );
};

GeneralDataForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  authors: PropTypes.arrayOf(PropTypes.object).isRequired,
  deletePublication: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  errors: PropTypes.objectOf(PropTypes.any).isRequired,
  handleChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  teams: PropTypes.arrayOf(PropTypes.object).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  touched: PropTypes.objectOf(PropTypes.any).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  values: PropTypes.objectOf(PropTypes.any).isRequired,
  reload: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  authors: selectPublicationAuthors,
  teams: selectPublicationTeamsAsOptions,
});

const mapDispatchToProps = {
  deletePublication: deletePublicationAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(GeneralDataForm);
