import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Card,
  Tab,
  Nav,
} from 'react-bootstrap';

import {
  FETCH_PUBLICATION_FILES,
  UPLOAD_PUBLICATION_FILES,
  DELETE_PUBLICATION_FILES,
  DOWNLOAD_PUBLICATION_FILES,
} from '../../../state/publication/publicationActions';

import RepositoryContainerPublication from './repositoryContainerPublication';
import RepositoryContainerPublicationPDF from './repositoryContainerPublicationPDF';
import PublicationProjects from './PublicationProjects';
import { selectUserPermissions } from '../../../state/user/selectors';
import { userWithPermission } from '../../../utils';

const Tabs = ({
  deleteFile,
  downloadFile,
  files,
  numberOfFilesUploading,
  filesPDF,
  fetchFiles,
  id,
  projects,
  type,
  uploadFile,
  pdfVisibility,
  userPermissions,
}) => {
  useEffect(() => {
    fetchFiles(id, type.toLowerCase(), 'PDF');
    fetchFiles(id, type.toLowerCase(), 'source');
  }, [id, fetchFiles, type]);

  return (
    <Row>
      <Col>
        <Card>
          <Tab.Container id="left-tabs-example" defaultActiveKey="wp">
            <Card.Header>
              <Nav variant="tabs">
                <Nav.Item>
                  <Nav.Link eventKey="repository">Media Repository</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="pdf">PDF File</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="projects">Projects</Nav.Link>
                </Nav.Item>
              </Nav>
            </Card.Header>
            <Card.Body>
              <Tab.Content>
                <Tab.Pane eventKey="media" />
                <Tab.Pane eventKey="repository">
                  <RepositoryContainerPublication
                    files={files}
                    numberOfFilesUploading={numberOfFilesUploading}
                    addFile={uploadFile}
                    deleteFile={deleteFile}
                    downloadFile={downloadFile}
                    id={id}
                    publicationType={type.toLowerCase()}
                    category="source"
                  />
                </Tab.Pane>
              </Tab.Content>
              <Tab.Content>
                <Tab.Pane eventKey="pdf">
                  <RepositoryContainerPublicationPDF
                    files={filesPDF}
                    numberOfFilesUploading={numberOfFilesUploading}
                    addFile={uploadFile}
                    deleteFile={deleteFile}
                    downloadFile={downloadFile}
                    id={id}
                    publicationType={type.toLowerCase()}
                    category="PDF"
                    pdfVisibility={pdfVisibility}
                  />
                </Tab.Pane>
              </Tab.Content>
              <Tab.Content>
                <Tab.Pane eventKey="projects">
                  {!userWithPermission(userPermissions, 'PUBLICATIONS_DATA_WRITE')
                    ? (<div>Not available</div>)
                    : (
                      <PublicationProjects
                        publicationId={id}
                        publicationType={type}
                        linkedProjects={projects}
                      />
                    )}
                </Tab.Pane>
              </Tab.Content>
            </Card.Body>
          </Tab.Container>
        </Card>
      </Col>
    </Row>
  );
};

Tabs.defaultProps = {
  files: [],
  filesPDF: [],
};

Tabs.propTypes = {
  deleteFile: PropTypes.func.isRequired,
  downloadFile: PropTypes.func.isRequired,
  fetchFiles: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  files: PropTypes.arrayOf(PropTypes.any),
  // eslint-disable-next-line react/forbid-prop-types
  numberOfFilesUploading: PropTypes.objectOf(PropTypes.any).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  filesPDF: PropTypes.arrayOf(PropTypes.any),
  id: PropTypes.string.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  isResearcher: PropTypes.bool,
  type: PropTypes.string.isRequired,
  uploadFile: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  projects: PropTypes.arrayOf(PropTypes.object).isRequired,
  pdfVisibility: PropTypes.bool.isRequired,
  userPermissions: PropTypes.arrayOf(PropTypes.string).isRequired,
};

Tabs.defaultProps = {
  isResearcher: false,
};

const mapStateToProps = (state) => ({
  files: state.publication.files,
  numberOfFilesUploading: state.publication.numberOfFilesUploading,
  filesPDF: state.publication.filesPDF,
  userPermissions: selectUserPermissions(state),
});

const mapDispatchToProps = (dispatch) => ({
  deleteFile: (data) => dispatch(DELETE_PUBLICATION_FILES(data)),
  downloadFile: (data) => dispatch(DOWNLOAD_PUBLICATION_FILES(data)),
  fetchFiles: (id, pubType, category) => dispatch(FETCH_PUBLICATION_FILES(id, pubType, category)),
  uploadFile: (data, category, attribute) => dispatch(UPLOAD_PUBLICATION_FILES(data, category, attribute)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Tabs);
