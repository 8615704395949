import { put, call, takeLatest } from 'redux-saga/effects';
import { Store as store } from 'react-notifications-component';
import axios from '../../axios';
import {
  MutateDeleteType,
  QueryAllTypes,
} from '../../graphql/queries/types';
import {
  DELETE_TYPE,
  deleteTypeError,
  deleteTypeSuccess,
  FETCH_ALL_TYPES,
  fetchAllTypes,
  fetchAllTypesError,
  fetchAllTypesSuccess,
} from './typesActions';
import catchErrors from '../../graphql/sagas';

export function* getAllTypes() {
  const response = yield call(axios.post, '/', { query: QueryAllTypes });
  yield put(fetchAllTypesSuccess(response.data.data.types));
}

export function* deleteType(action) {
  const response = yield call(axios.post, '/', { query: MutateDeleteType(action.typeId) });
  if (response.data.data.deleteType === false) {
    throw new Error('Type could not be deleted');
  }

  yield put(deleteTypeSuccess(response.data));
  yield put(fetchAllTypes());
  store.addNotification({
    title: 'SUCCESS!',
    message: 'Type deleted successfully',
    type: 'success',
    insert: 'top',
    container: 'top-right',
    dismiss: {
      duration: 5000,
      onScreen: true,
    },
  });
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(
    DELETE_TYPE,
    catchErrors({
      onErrorAction: deleteTypeError,
      overrideErrorMessage: 'Unable to delete type',
    })(deleteType),
  );

  yield takeLatest(
    FETCH_ALL_TYPES,
    catchErrors({
      onErrorAction: fetchAllTypesError,
      overrideErrorMessage: 'Unable to retrieve types',
    })(getAllTypes),
  );
}
