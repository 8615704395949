import { Col, Form, Row } from 'react-bootstrap';
import Select from 'react-select';
import { ErrorMessage, useFormikContext } from 'formik';
import React from 'react';
import PropTypes from 'prop-types';

function ProceedingsSelectorRow({
  proceedings, conferences, field,
}) {
  const { errors } = useFormikContext();
  const { touched } = useFormikContext();
  const { values } = useFormikContext();
  const { setFieldValue } = useFormikContext();
  return (
    <Row style={{ marginTop: '2rem' }}>
      <Col sm={4}>
        <Form.Label>Conference</Form.Label>
        <Form.Text muted>
          Missing conference? Add via settings -> venues (afterwards click on 'Reload Options' to see your added venue)
        </Form.Text>
        <Select
          onChange={(event) => setFieldValue(field, proceedings.filter((p) => p.conference.id === event.value).sort((a, b) => a.label.localeCompare(b.label))[0]?.value)}
          options={conferences}
          value={proceedings && conferences && values[field] ? (
            conferences.find(
              (c) => c.value === (proceedings.find((p) => p.value === values[field])).conference.id,
            )
          ) : undefined}
          className={touched[field] && errors[field] ? 'error' : null}
        />
      </Col>
      <Col sm={5}>
        <Form.Label>Proceedings</Form.Label>
        <Form.Text muted>
          Missing proceeding? Add via new publication -> new proceeding
        </Form.Text>
        <Select
          name={field}
          onChange={(event) => setFieldValue(field, event.value)}
          isDisabled={values[field] === undefined || values[field] === null}
          placeholder={values[field] === undefined ? 'Please add a proceeding for this conference first' : 'First select a conference'} // undefined means selected venue without proceedings
          options={(() => {
            const currentProceedings = proceedings.find((pr) => pr.value === values[field]);
            if (values[field]) {
              return proceedings
                .filter((p) => p.conference.id === conferences.find((c) => c.value === currentProceedings.conference.id).value)
                .sort((a, b) => a.label.localeCompare(b.label));
            }
            return proceedings;
          })()}
          value={proceedings && values[field] ? (
            proceedings.find(
              (p) => p.value === values[field],
            )
          ) : null}
          className={errors[field] ? 'error' : null}
        />
        <div className="error-message">
          <ErrorMessage name={field} />
        </div>
      </Col>
    </Row>
  );
}

ProceedingsSelectorRow.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  conferences: PropTypes.arrayOf(PropTypes.object).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  proceedings: PropTypes.arrayOf(PropTypes.object).isRequired,
  field: PropTypes.string.isRequired,
};
export default ProceedingsSelectorRow;
