import {
  put, take, fork, call,
} from 'redux-saga/effects';
import { Store as store } from 'react-notifications-component';
import { axiosClientNoGraphl } from '../../axios';
import {
  UPLOAD_PROFILE_IMAGE_ERROR,
  UPLOAD_PROFILE_IMAGE_SUCCESS,
} from './profileAction';
import { failedNotificationTimer } from '../../config/constants';

function* uploadImage(action) {
  try {
    yield call(axiosClientNoGraphl.post, '/files/uploadProfilePicture', action.data, { headers: { 'content-type': 'multipart/form-data' } });
    yield put(UPLOAD_PROFILE_IMAGE_SUCCESS());
  } catch (e) {
    yield put(UPLOAD_PROFILE_IMAGE_ERROR(e));
    store.addNotification({
      title: 'ERROR!',
      message: `Failed to upload profile image: ${e.response.data}`,
      type: 'danger',
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: failedNotificationTimer,
        onScreen: true,
      },
    });
  }
}

export function* uploadProfileImageSagaWatcher() {
  while (true) {
    const action = yield take('UPLOAD_PROFILE_IMAGE');
    yield fork(uploadImage, action);
  }
}
