import _ from 'lodash';
import {
  DELETE_PUBLICATION,
  DELETE_PUBLICATION_ERROR,
  DELETE_PUBLICATION_SUCCESS,
  LINK_PROJECT_WITH_PUBLICATION,
  LINK_PROJECT_WITH_PUBLICATION_ERROR,
  LINK_PROJECT_WITH_PUBLICATION_SUCCESS,
  UNLINK_PROJECT_FROM_PUBLICATION,
  UNLINK_PROJECT_FROM_PUBLICATION_ERROR,
  UNLINK_PROJECT_FROM_PUBLICATION_SUCCESS,
} from './publicationActions';
import { uniqueArray } from '../../utils';

const publicationTypes = [
  { value: 'journal', label: 'Journal Article' },
  { value: 'bookchapter', label: 'Book Chapter' },
  { value: 'conferencepaper', label: 'Conference / Workshop Paper' },
  { value: 'report', label: 'Report' },
  { value: 'book', label: 'Book' },
  { value: 'proceeding', label: 'Proceedings' },
  { value: 'these', label: 'Thesis' },
  { value: 'patent', label: 'Patent' },
  // { value: 'rump', label: 'rump' },
  { value: 'abstractoftalk', label: 'Abstract of Talk' },
  { value: 'talk', label: 'Talk' },
];

const statusOptions = ['Accepted', 'Published', 'New'].map((value) => ({
  label: value,
  value,
}));

const defaultState = {
  isLoading: false,
  publicationByYear: [],
  publicationByAuthor: [],
  publicationByCategory: {},
  files: [],
  filesPDF: [],
  detail: {
    selectedId: null,
    data: {},
  },
  error: '',
  filter: {
    authors: [],
    publicationTypes: [],
    years: [
      { value: new Date().getFullYear(), label: new Date().getFullYear() },
    ],
    limitInternational: false,
    limitReviewed: false,
    limitAuthor: false,
    team: false,
    status: 'published',
  },
  initialValues: {
    types: null,
    volume: '', // article optional
    vol: '', // volume for 'proceedings'
    doi: '',
    title: '',
    isbn: '',
    number: '',
    thesisType: '',
    pages: undefined, // string of integer
    primaryAuthor: undefined, // single person
    authors: undefined, // array of publication users {first, last}
    webVersion: '', // url
    status: statusOptions[0].value,
    team: undefined, // team id from dropdown
    reviewed: false,
    complete: false,
    presentedAtRump: false,
    visibility: false,
    conference: undefined,
    country: undefined,
    city: undefined,
    state: undefined,
    editor: [],
    presenters: [],
    proceeding: undefined,
    date: '',
  },
  authorsOption: [], // filled with action
  booksOption: [],
  conferencesOption: [],
  statusOptions,
  seriesOption: [],
  countriesOptions: [],
  teamsOptions: [],
  proceedingsOption: [],
  volumesOptions: [],
  journalOption: [],
  publicationTypes,
  institutions: [],
  theseTitles: [],
  talkTitles: [],
  reportTitles: [],
  patentTitles: [],
  journalArticleTitles: [],
  conferencepaperTitles: [],
  bookTitles: [],
  bookchapterTitles: [],
  abstractoftalkTitles: [],
  isFilesLoading: true,
  numberOfFilesUploading: { public: { source: 0, PDF: 0 }, private: { source: 0 } },
  teams: [],
};

const sortedPeople = (people) => _.orderBy(
  people
    .map((ppl) => ({
      ...ppl,
      first: ppl.first.trim(),
      last: ppl.last.trim(),
    }))
    .filter((ppl) => !_.isEmpty(ppl.first) && !_.isEmpty(ppl.last))
    .map((ppl) => ({
      label: `${ppl.last}, ${ppl.first}${ppl.userid ? ` (${ppl.userid})` : ''}`,
      value: ppl.id,
    })),
  ['label'],
  ['asc'],
);

const publication = (state = defaultState, action) => {
  switch (action.type) {
    case 'FETCH_PUBLICATION_SUCCESS':
      switch (action.data.type) {
        case 'journal':
          return {
            ...state,
            isLoading: false,
            detail: {
              ...state.detail,
              selectedId: action.data.id,
              data: action.data,
            },
            initialValues: {
              ...state.initialValues,
              types: 'journal',
              country: action.data.country,
              visibility: action.data.visibility || false,
              publication: action.data.publication
                ? action.data.publication.id
                : null,
              volume: action.data.publication
                ? action.data.publication.volume
                : null,
              journal: action.data.publication.journal
                ? action.data.publication.journal.id
                : null,
              appeared: action.data.publication
                ? action.data.publication.appeared.split(' ')[0]
                : null,
              number: action.data.publication
                ? action.data.publication.number
                : null,
              doi: action.data.doi,
              date: action.data.date ? action.data.date.split(' ')[0] : '',
              title: action.data.title,
              pages: action.data.pages, // string of integer
              primaryAuthor: action.data.firstAuthor
                ? action.data.firstAuthor.id
                : null,
              authors: sortedPeople(action.data.authors),
              webVersion: action.data.webVersion, // url
              status: action.data.status,
              team: action.data.Team ? action.data.Team.id : null,
              reviewed: action.data.reviewed,
              complete: action.data.complete,
            },
          };
        case 'proceeding':
          return {
            ...state,
            isLoading: false,
            detail: {
              ...state.detail,
              selectedId: action.data.id,
              data: action.data,
            },
            initialValues: {
              ...state.initialValues,
              authors: [],
              date: action.data.appeared,
              types: 'proceeding',
              city: action.data.city,
              visibility: action.data.visibility || false,
              state: action.data.state,
              country: action.data.country,
              edition: action.data.edition, // article optional
              vol: action.data.volume, // article optional
              doi: action.data.doi,
              title: undefined,
              editor: sortedPeople(action.data.editors), // array of publication users {first, last}
              webVersion: action.data.webVersion, // url
              status: action.data.status,
              team: action.data.team ? action.data.team.id : null,
              conference: action.data.conference
                ? action.data.conference.id
                : null,
              reviewed: action.data.reviewed,
              complete: action.data.complete,
            },
          };
        case 'conferencepaper':
          return {
            ...state,
            isLoading: false,
            detail: {
              ...state.detail,
              selectedId: action.data.id,
              data: action.data,
            },
            initialValues: {
              ...state.initialValues,
              appeared: action.data.appeared,
              visibility: action.data.visibility || false,
              date: action.data.date ? action.data.date.split(' ')[0] : '',
              types: 'conferencepaper',
              doi: action.data.doi,
              editor: sortedPeople(action.data.editors),
              webVersion: action.data.webVersion,
              title: action.data.title,
              proceedingTitle: action.data.proceedingTitle,
              pages: action.data.pages,
              publisher: action.data.publisher,
              primaryAuthor: action.data.firstAuthor
                ? action.data.firstAuthor.id
                : null,
              authors: sortedPeople(action.data.authors),
              publication: action.data.publication.id,
              conference: action.data.publication.conference.id,
              status: action.data.status,
              from: action.data.publication.from,
              to: action.data.publication.to,
              city: action.data.publication.city,
              state: action.data.publication.state,
              country: action.data.publication.country,
              team: action.data.team ? action.data.team.id : null,
              presentedAtRump: action.data.presentedAtRump,
              complete: action.data.complete,
            },
          };
        case 'book':
          return {
            ...state,
            isLoading: false,
            detail: {
              ...state.detail,
              selectedId: action.data.id,
              data: action.data,
            },
            initialValues: {
              ...state.initialValues,
              appeared: action.data.appeared,
              visibility: action.data.visibility || false,
              date: action.data.date ? action.data.date.split(' ')[0] : '',
              types: 'book',
              isbn: action.data.isbn,
              country: action.data.country,
              vol: action.data.volume,
              doi: action.data.doi,
              title: action.data.title,
              publisher: action.data.publisher,
              primaryAuthor: action.data.firstAuthor
                ? action.data.firstAuthor.id
                : null,
              authors: sortedPeople(action.data.authors),
              editor: sortedPeople(action.data.editors), // array of publication users {first, last}
              webVersion: action.data.webVersion, // url
              status: action.data.status,
              pages: action.data.pages,
              team: action.data.team ? action.data.team.id : null,
              reviewed: action.data.reviewed,
              complete: action.data.complete,
            },
          };
        case 'abstractoftalk':
          return {
            ...state,
            isLoading: false,
            detail: {
              ...state.detail,
              selectedId: action.data.id,
              data: action.data,
            },
            initialValues: {
              ...state.initialValues,
              title: action.data.title,
              visibility: action.data.visibility || false,
              types: 'abstractoftalk',
              doi: action.data.doi,
              date: action.data.date ? action.data.date.split(' ')[0] : '',
              primaryAuthor: action.data.firstAuthor
                ? action.data.firstAuthor.id
                : null,
              authors: sortedPeople(action.data.authors),
              conference: action.data.publication
                ? action.data.publication.id
                : null,
              webVersion: action.data.webVersion, // url
              status: action.data.status,
              pages: action.data.pages,
              team: action.data.team ? action.data.team.id : null,
            },
          };
        case 'talk':
          return {
            ...state,
            isLoading: false,
            detail: {
              ...state.detail,
              selectedId: action.data.id,
              data: action.data,
            },
            initialValues: {
              ...state.initialValues,
              types: 'talk',
              visibility: action.data.visibility || false,
              doi: action.data.doi,
              liriaslink: action.data.liriaslink,
              event: action.data.event,
              date: action.data.from ? action.data.from.split(' ')[0] : '',
              city: action.data.city,
              country: action.data.country,
              title: action.data.title,
              talkTitle: action.data.title,
              input: action.data.input,
              primaryAuthor: action.data.firstAuthor
                ? action.data.firstAuthor.id
                : null,
              authors: sortedPeople(action.data.authors),
              conference: action.data.conference
                ? action.data.conference.id
                : null,
              typeEvent: action.data.typeEvent,
              presenters: sortedPeople(action.data.presenters),
              webVersion: action.data.webVersion, // url
              status: action.data.status,
              pages: action.data.pages,
              team: action.data.team ? action.data.team.id : null,
            },
          };
        case 'these':
          return {
            ...state,
            isLoading: false,
            detail: {
              ...state.detail,
              selectedId: action.data.id,
              data: action.data,
            },
            initialValues: {
              ...state.initialValues,
              types: 'these',
              visibility: action.data.visibility || false,
              doi: action.data.doi,
              title: action.data.title,
              primaryAuthor: action.data.firstAuthor
                ? action.data.firstAuthor.id
                : null,
              authors: sortedPeople(action.data.authors),
              promotors: action.data.promotors.map((promote) => ({
                label: `${promote.last}, ${promote.first}`,
                value: promote.id,
              })),
              thesisType: action.data.thesisType || action.data.type,
              institution: action.data.institution,
              date: action.data.date ? action.data.date.split(' ')[0] : '',
              country: action.data.country,
              webVersion: action.data.webVersion, // url
              status: action.data.status,
              pages: action.data.pages,
              team: action.data.team ? action.data.team.id : null,
            },
          };
        case 'report':
          return {
            ...state,
            isLoading: false,
            detail: {
              ...state.detail,
              selectedId: action.data.id,
              data: action.data,
            },
            initialValues: {
              ...state.initialValues,
              types: 'report',
              visibility: action.data.visibility || false,
              doi: action.data.doi,
              title: action.data.title,
              primaryAuthor: action.data.firstAuthor
                ? action.data.firstAuthor.id
                : null,
              authors: sortedPeople(action.data.authors),
              note: action.data.note,
              date: action.data.date ? action.data.date.split(' ')[0] : '',
              webVersion: action.data.webVersion, // url
              status: action.data.status,
              pages: action.data.pages,
              team: action.data.team ? action.data.team.id : null,
            },
          };
        case 'patent':
          return {
            ...state,
            isLoading: false,
            detail: {
              ...state.detail,
              selectedId: action.data.id,
              data: action.data,
            },
            initialValues: {
              ...state.initialValues,
              types: 'patent',
              visibility: action.data.visibility || false,
              doi: action.data.doi,
              title: action.data.title,
              primaryAuthor: action.data.firstAuthor
                ? action.data.firstAuthor.id
                : null,
              authors: sortedPeople(action.data.authors),
              number: action.data.number,
              applicant: action.data.applicant,
              description: action.data.description,
              date: action.data.date ? action.data.date.split(' ')[0] : '',
              webVersion: action.data.webVersion, // url
              status: action.data.status,
              pages: action.data.pages,
              team: action.data.team ? action.data.team.id : null,
            },
          };
        case 'bookchapter':
          return {
            ...state,
            isLoading: false,
            detail: {
              ...state.detail,
              selectedId: action.data.id,
              data: action.data,
            },
            initialValues: {
              ...state.initialValues,
              types: 'bookchapter',
              visibility: action.data.visibility || false,
              date: action.data.date ? action.data.date.split(' ')[0] : '',
              doi: action.data.doi,
              title: action.data.title,
              primaryAuthor: action.data.firstAuthor
                ? action.data.firstAuthor.id
                : null,
              authors: sortedPeople(action.data.authors),
              book: action.data.publication ? action.data.publication.id : null,
              webVersion: action.data.webVersion, // url
              status: action.data.status,
              pages: action.data.pages,
              team: action.data.team ? action.data.team.id : null,
              thesisType: '',
            },
          };
        default:
          return state;
      }
    case 'CLEAR_PUBLICATION_DEFAULTS':
      return {
        ...state,
        isLoading: false,
        initialValues: {
          types: null,
          volume: '', // article optional
          vol: '', // volume for 'proceedings'
          doi: '',
          title: '',
          isbn: '',
          pages: undefined, // string of integer
          primaryAuthor: undefined,
          authors: [],
          webVersion: undefined, // url
          status: statusOptions[0].value,
          team: undefined, // team id from dropdown
          reviewed: false,
          complete: false,
          visibility: false,
          presentedAtRump: false,
          conference: undefined,
          country: undefined,
          city: undefined,
          state: undefined,
          editor: [],
          proceeding: undefined,
        },
      };
    case 'CREATE_PUBLICATION':
      return { ...state, isLoading: true };
    case 'UPDATE_PUBLICATION':
      return { ...state, isLoading: true };
    case 'FETCH_PUBLICATION_ERROR':
      return {
        ...state,
        detail: {
          ...state.detail,
          data: action.error,
        },
        isLoading: false,
      };
    case 'FETCH_ALL_PUBLICATIONS_SUCCESS':
      return {
        ...state,
        publicationByYear: action.data.publicationByYear,
        publicationByAuthor: action.data.publicationByAuthor,
        publicationByCategory: action.data.publicationByCategory,
        isLoading: false,
      };
    case 'FETCH_PUBLICATION_PEOPLE_SUCCESS':
      return {
        ...state,
        authorsOption: sortedPeople(action.data),
      };
    case 'CREATE_EXTERNAL_PEOPLE_SUCCESS':
      return {
        ...state,
        authorsOption: [
          {
            label: `${action.data.last}, ${action.data.first}`,
            value: action.data.id,
          },
          ...state.authorsOption,
        ],
      };
    case 'FETCH_PUBLICATION_PEOPLE_ERROR':
      return { ...state, authorsOption: undefined };
    case 'FETCH_PUBLICATION_VOLUMES_SUCCESS':
      return {
        ...state,
        volumesOptions: action.data.map((volume) => ({
          label: `${volume.journal.title} (${new Date(Date.parse(volume.appeared)).getFullYear() || 'n.d.'
          })`,
          value: volume.id,
        })),
      };
    case 'FETCH_PUBLICATION_VOLUMES_ERROR':
      return { ...state, volumesOptions: undefined };
    case 'FETCH_PUBLICATION_TEAMS_SUCCESS':
      return {
        ...state,
        teamsOptions: action.data.map((team) => ({
          label: team.name,
          value: team.id,
        })),
        teams: action.data,
      };
    case 'FETCH_PUBLICATION_PROCEEDINGS_SUCCESS':
      return {
        ...state,
        proceedingsOption: action.data.rawProceedings
          ? action.data.rawProceedings
            .filter((p) => p !== null && p.conference)
            .map((proceeding) => ({
              label: `${proceeding.conference.proceedings} | ${new Date(
                Date.parse(proceeding.from),
              ).getFullYear() || 'n.d.'
              } (#${proceeding.id})`,
              value: proceeding.id,
              conference: proceeding.conference,
            }))
          : [],
        conferencesOption: action.data.conferences
          .filter((p) => p !== null)
          .map((confer) => ({
            label: `${confer.acronym} - ${confer.conference}`,
            value: confer.id,
            proceedings: confer.proceedings,
            series: confer.series ? confer.series.id : null,
          })),
        seriesOption: action.data.series
          .filter((p) => p !== null)
          .map((series) => ({
            label: series.name,
            value: series.id,
          })),
        booksOption: action.data.allBooks
          .filter((p) => p !== null)
          .map((book) => ({
            label: book.title,
            value: book.id,
          })),
        journalOption: action.data.journals
          .filter((p) => p !== null)
          .map((book) => ({
            label: book.title,
            value: book.id,
          })),
        institutions: action.data.institution,
        theseTitles: action.data.theseTitle,
        talkTitles: action.data.talkTitle,
        reportTitles: action.data.reportTitle,
        patentTitles: action.data.patentTitle,
        journalArticleTitles: action.data.journalArticleTitle,
        conferencepaperTitles: action.data.conferenceArticleTitle,
        bookTitles: action.data.bookTitle,
        bookchapterTitles: action.data.bookArticleTitle,
        abstractoftalkTitles: action.data.abstractOfTalkTitle,
      };
    case 'FETCH_PUBLICATION_COUNTRIES_SUCCESS':
      return {
        ...state,
        countriesOptions: action.data
          .filter((c) => c !== null)
          .map((country) => ({
            label: country.name,
            value: country.name,
          })),
      };
    case 'FETCH_PUBLICATION_COUNTRIES_ERROR':
      return { ...state, countriesOptions: undefined };
    case 'FETCH_PUBLICATION_TEAMS_ERROR':
      return { ...state, teamsOptions: undefined };
    case 'FETCH_PUBLICATION_PROCEEDINGS_ERROR':
      return { ...state, proceedingsOption: undefined };
    case 'FETCH_ALL_PUBLICATIONS_ERROR':
      return { ...state, error: action.error, isLoading: false };
    case 'FETCH_PUBLICATION_FILES':
      return { ...state, isFilesLoading: true };
    case 'FETCH_PUBLICATION_FILES_SUCCESS':
      return { ...state, files: action.data, isFilesLoading: false };
    case 'FETCH_PUBLICATION_FILES_PDF_SUCCESS':
      return { ...state, filesPDF: action.data, isFilesLoading: false };
    case 'FETCH_PUBLICATION_FILES_ERROR':
      return { ...state, error: action.error, isFilesLoading: false };
    case 'UPLOAD_PUBLICATION_FILES':
      return { ...state, numberOfFilesUploading: { ...state.numberOfFilesUploading, [action.attribute]: { ...state.numberOfFilesUploading[action.attribute], [action.category]: state.numberOfFilesUploading[action.attribute][action.category] + 1 } } };
    case 'UPLOAD_PUBLICATION_FILES_SUCCESS':
      return {
        ...state,
        files: uniqueArray([...state.files, action.data]),
        numberOfFilesUploading: { ...state.numberOfFilesUploading, [action.attribute]: { ...state.numberOfFilesUploading[action.attribute], [action.category]: state.numberOfFilesUploading[action.attribute][action.category] - 1 } },
      };
    case 'UPLOAD_PUBLICATION_FILES_PDF_SUCCESS':
      return {
        ...state,
        filesPDF: [action.data],
        isFilesLoading: false,
        numberOfFilesUploading: { ...state.numberOfFilesUploading, [action.attribute]: { ...state.numberOfFilesUploading[action.attribute], [action.category]: state.numberOfFilesUploading[action.attribute][action.category] - 1 } },
      };
    case 'UPLOAD_PUBLICATION_FILES_ERROR':
      return { ...state, error: action.error, numberOfFilesUploading: { ...state.numberOfFilesUploading, [action.attribute]: { ...state.numberOfFilesUploading[action.attribute], [action.category]: state.numberOfFilesUploading[action.attribute][action.category] - 1 } } };
    case 'SET_PUBLICATION_FILTER_YEARS':
      return {
        ...state,
        filter: { ...state.filter, years: action.data },
        isLoading: true,
      };
    case 'SET_PUBLICATION_FILTER_PUBLISHING_DATE_FROM':
      return {
        ...state,
        filter: { ...state.filter, publishingDateFrom: action.data },
        isLoading: true,
      };
    case 'SET_PUBLICATION_FILTER_PUBLISHING_DATE_TO':
      return {
        ...state,
        filter: { ...state.filter, publishingDateTo: action.data },
        isLoading: true,
      };
    case 'SET_PUBLICATION_FILTER_CONFERENCE_DATE_FROM':
      return {
        ...state,
        filter: { ...state.filter, conferenceDateFrom: action.data },
        isLoading: true,
      };
    case 'SET_PUBLICATION_FILTER_CONFERENCE_DATE_TO':
      return {
        ...state,
        filter: { ...state.filter, conferenceDateTo: action.data },
        isLoading: true,
      };
    case 'SET_PUBLICATION_FILTER_PUBLICATIONTYPES':
      return {
        ...state,
        filter: { ...state.filter, publicationTypes: action.data },
        isLoading: true,
      };
    case 'SET_PUBLICATION_FILTER_AUTHORS':
      return {
        ...state,
        filter: { ...state.filter, authors: action.data },
        isLoading: true,
      };
    case 'SET_PUBLICATION_FILTER_STATUS':
      return {
        ...state,
        filter: { ...state.filter, status: action.data?.value },
        isLoading: true,
      };
    case 'SET_PUBLICATION_FILTER_REVIEWED':
      return {
        ...state,
        filter: { ...state.filter, limitReviewed: action.data },
        isLoading: true,
      };
    case 'SET_PUBLICATION_FILTER_AUTHOR_LIMIT':
      return {
        ...state,
        filter: { ...state.filter, limitAuthor: action.data },
        isLoading: true,
      };
    case 'SET_PUBLICATION_FILTER_INTERNATIONAL':
      return {
        ...state,
        filter: { ...state.filter, limitInternational: action.data },
        isLoading: true,
      };
    case 'SET_PUBLICATION_FILTER_TEAM':
      return {
        ...state,
        filter: { ...state.filter, team: action.data },
        isLoading: true,
      };
    case 'DELETE_PUBLICATION_FILES':
      return { ...state, isFilesLoading: true };
    case 'DELETE_PUBLICATION_FILES_SUCCESS':
      return {
        ...state,
        files: state.files.filter(
          (file) => file.fileName !== action.data.fileName
            || file.visibility !== action.data.attribute,
        ),
        isFilesLoading: false,
      };
    case 'DELETE_PUBLICATION_FILES_PDF_SUCCESS':
      return {
        ...state,
        filesPDF: state.filesPDF.filter(
          (file) => file.fileName !== action.data.fileName
            || file.visibility !== action.data.attribute,
        ),
        isFilesLoading: false,
      };
    case 'DELETE_PUBLICATION_FILES_ERROR':
      return { ...state, error: action.error, isFilesLoading: false };

    case LINK_PROJECT_WITH_PUBLICATION:
      return { ...state, isLoading: false };
    case LINK_PROJECT_WITH_PUBLICATION_ERROR:
      return { ...state, error: action.error, isLoading: false };
    case LINK_PROJECT_WITH_PUBLICATION_SUCCESS:
      return { ...state, isLoading: false };

    case UNLINK_PROJECT_FROM_PUBLICATION:
      return { ...state, isLoading: false };
    case UNLINK_PROJECT_FROM_PUBLICATION_ERROR:
      return { ...state, error: action.error, isLoading: false };
    case UNLINK_PROJECT_FROM_PUBLICATION_SUCCESS:
      return { ...state, isLoading: false };

    case DELETE_PUBLICATION:
    case DELETE_PUBLICATION_SUCCESS:
      return { ...state, isLoading: false };
    case DELETE_PUBLICATION_ERROR:
      return { ...state, error: action.payload.error, isLoading: false };

    default:
      return state;
  }
};

export default publication;
