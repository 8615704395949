import {
  put, call, takeLatest,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { Store as store } from 'react-notifications-component';
import axios from '../../axios';
import {
  QueryOneCountry,
  MutateCreateCountry,
  MutateUpdateCountry,
} from '../../graphql/queries/country';
import catchErrors from '../../graphql/sagas';
import {
  fetchOneCountrySuccess,
  fetchOneCountryError,
  FETCH_ONE_COUNTRY,
  updateCountrySuccess,
  updateCountryError,
  UPDATE_COUNTRY,
} from './countryActions';

export function* getOneCountry(action) {
  const response = yield call(axios.post, '/', { query: QueryOneCountry(action.data.id) });
  yield put(fetchOneCountrySuccess(response.data.data.country));
}

export function* updateOneCountry(action) {
  const { id } = action.data;
  const msg = id ? 'updated' : 'saved';

  const query = id
    ? MutateUpdateCountry(action.data)
    : MutateCreateCountry(action.data);

  yield call(axios.post, '/', { query });
  yield put(updateCountrySuccess());
  yield put(push('/admin/settings/countries'));

  store.addNotification({
    title: 'SUCCESS!',
    message: `Country succesfully ${msg}!`,
    type: 'success',
    insert: 'top',
    container: 'top-right',
    dismiss: {
      duration: 5000,
      onScreen: true,
    },
  });
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(
    FETCH_ONE_COUNTRY,
    catchErrors({
      onErrorAction: fetchOneCountryError,
      overrideErrorMessage: 'Unable to retrieve entry',
    })(getOneCountry),
  );

  yield takeLatest(
    UPDATE_COUNTRY,
    catchErrors({
      onErrorAction: updateCountryError,
      overrideErrorMessage: 'Unable to save this update',
    })(updateOneCountry),
  );
}
