import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  Tab,
  Nav,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import connect from 'react-redux/es/connect/connect';
import Select from 'react-select';
import Datetime from 'react-datetime';
import { ErrorMessage, Formik } from 'formik';
import * as Yup from 'yup';
import {
  CLEAR_PROJECT_FORM,
  FETCH_PROJECT_TYPES,
  FETCH_PROJECT_CALLS,
  FETCH_PROJECT,
  CREATE_ONE_PROJECT,
  UPDATE_ONE_PROJECT,
  FETCH_PROJECT_FILES,
  POST_PROJECT_FILE,
  DELETE_PROJECT_FILE,
  DOWNLOAD_PROJECT_FILE,
  ADD_PROJECT_PARTNER,
  REMOVE_PROJECT_PARTNER,
  ADD_PROJECT_MEMBER,
  REMOVE_PROJECT_MEMBER,
} from '../../../state/project/projectActions';
import { FETCH_PUBLICATION_PEOPLE } from '../../../state/publication/publicationActions';

import {
  FETCH_ALL_FILTERED_PEOPLE,
} from '../../../state/people/peopleActions';
import { fetchAllFunders } from '../../../state/funders/fundersActions';
import {
  fetchAllPartners as fetchAllPartnersAction,
} from '../../../state/partners/partnersActions';
import {
  WorkPackageContainer,
  RepositoryContainer,
  ProjectTeamContainer,
  PartnersContainer,
  ProjectPublicationsContainer,
} from '../index';
import { cosicContactRoles, projectStatuses } from '../../../config/optionValues';
import {
  selectProjectDetail,
} from '../../../state/project/selectors';

import { Loader } from '../../../component/index';
import { customFilterMatchAll } from '../../../component/publicationFormatTypes/typeHelper';

const Project = (props) => {
  const {
    authorsOption,
    fetchProjectDetails,
    detail,
    calls,
    teams,
    types,
    funders,
    fetchAuthors,
    fetchCalls,
    fetchFunder,
    fetchTypes,
    loading,
    isFunderLoading,
    updateProject,
    clearForm,
    createProject,
    initialValues,
    files,
    fetchFiles,
    addFile,
    numberOfFilesUploading,
    deleteFile,
    downloadFile,
    fetchPartners,
    partners,
    addPartner,
    removePartner,
    addMember,
    removeMember,
    fetchPeople,
    people,
  } = props;
  const { id } = useParams();
  const [reloads, setReloads] = useState(0);
  const emptyOption = {
    label: <em style={{ color: 'gray' }}>- None -</em>,
    value: null,
  };
  const teamsOptions = teams && Array.isArray(teams) ? [
    emptyOption,
    ...teams.map((team) => ({
      label: team.name,
      value: team.id,
    }))] : [];
  const callsOptions = calls && Array.isArray(calls) ? calls.map((type) => ({
    label: type.name,
    value: type.id,
  })) : [];
  const fundersOptions = funders && Array.isArray(funders) ? funders.map((fund) => ({
    label: fund.name,
    value: fund.id,
  })) : [];
  const typesOptions = types && Array.isArray(types) ? types.map((type) => ({
    label: type.name,
    value: type.id,
  })) : [];

  useEffect(() => {
    if (id) {
      fetchProjectDetails(id);
      fetchFiles(id);
    } else {
      clearForm();
    }
  }, [
    clearForm,
    fetchProjectDetails,
    fetchFiles,
    id,
  ]);

  useEffect(() => {
    fetchAuthors();
    fetchCalls();
    fetchFunder();
    fetchTypes();
    fetchPartners();
    fetchPeople({});
  }, [
    reloads,
    fetchAuthors,
    fetchCalls,
    fetchFunder,
    fetchTypes,
    fetchPartners,
    fetchPeople,
  ]);

  const handleCallChange = (call, setFieldValue) => {
    setFieldValue('call', call.value);
  };

  const handleTeamChange = (team, setFieldValue) => {
    setFieldValue('team', team);
  };

  const handleTypeChange = (type, setFieldValue) => {
    setFieldValue('type', type.value);
  };

  const handleFunderChange = (type, setFieldValue) => {
    setFieldValue('funder', type.value);
  };

  const handleStatusChange = (status, setFieldValue) => {
    setFieldValue('status', status.value);
  };

  const handlePublishedChange = (event, setFieldValue) => {
    setFieldValue('published', event.target.checked);
  };

  const handleDatetimeChange = (dt, fieldName, format, setFieldValue) => {
    /*
      Work-around for `react-datetime`

      Change Formik value using `setFieldValue`.
      See:
      https://stackoverflow.com/questions/54039555/problem-with-saving-dt-with-react-datetime-inside-formik-component
    */
    if (typeof (dt) === 'string') {
      setFieldValue(fieldName, dt);
    } else {
      setFieldValue(fieldName, dt.format(format));
    }
  };

  const validationSchema = Yup.object()
    .shape({
      beginDate: Yup.string().nullable(),
      endDate: Yup.string().nullable(),
      abstract: Yup.string().nullable(),
      cosicContact: Yup.string().required().nullable(),
      cosicContactRole: Yup.string().required().nullable(),
      website: Yup.string().url('Must be a valid URL').nullable(),
      cosicBudget: Yup.number()
        .positive('Budget must be a positive number')
        .nullable(),
      name: Yup.string()
        .required('Name required')
        .nullable(),
      number: Yup.string().nullable(),
      type: Yup.number()
        .required('Type required')
        .nullable(),
      status: Yup.string()
        .required('Status required')
        .nullable(),
      acronym: Yup.string()
        .required('Acronym required')
        .nullable(),
      funder: Yup.number()
        .nullable(),
      action: Yup.string()
        .nullable(),
      webVersionRepository: Yup.string()
        .nullable(),
      sap: Yup.string()
        .nullable(),
      call: Yup.number()
        .nullable(),
      team: Yup.string().required().nullable(),
    });

  const confirmationText = id ? 'Save' : 'Create';

  const showTabs = () => (
    <Row>
      <Col>
        <Card>
          <Tab.Container id="left-tabs-example" defaultActiveKey="wp">
            <Card.Header>
              <Nav variant="tabs">
                <Nav.Item>
                  <Nav.Link eventKey="wp">WP's</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="team">Project Team</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="publications">Publications</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="repository">Media Repository</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="partners">Partners</Nav.Link>
                </Nav.Item>
              </Nav>
            </Card.Header>
            <Card.Body>
              <Tab.Content>
                <Tab.Pane eventKey="wp">
                  <WorkPackageContainer projectId={id} />
                </Tab.Pane>
                <Tab.Pane eventKey="team">
                  {
                    detail && detail.teamMembers
                      ? (
                        <ProjectTeamContainer
                          id={id}
                          addMember={addMember}
                          removeMember={removeMember}
                          members={detail.teamMembers}
                          people={people}
                        />
                      )
                      : <></>
                  }
                </Tab.Pane>
                <Tab.Pane eventKey="publications">
                  {
                    detail.publications
                      ? <ProjectPublicationsContainer publications={detail.publications} />
                      : <></>
                  }
                </Tab.Pane>
                <Tab.Pane eventKey="repository">
                  <RepositoryContainer
                    files={files}
                    numberOfFilesUploading={numberOfFilesUploading}
                    addFile={addFile}
                    deleteFile={deleteFile}
                    downloadFile={downloadFile}
                    id={id}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="partners">
                  {detail.partners && partners && id
                    ? (
                      <PartnersContainer
                        partners={detail.partners}
                        allPartners={partners}
                        addPartner={addPartner}
                        removePartner={removePartner}
                        id={id}
                        people={people}
                      />
                    )
                    : <></>}
                </Tab.Pane>
              </Tab.Content>
            </Card.Body>
          </Tab.Container>
        </Card>
      </Col>
    </Row>
  );

  return (
    <Container fluid>
      <Row>
        <Col>
          <Card className="filter-card">
            <Card.Header>
              General Data
              {!loading && (
              <Button
                type="submit"
                variant="secundary"
                className="btn btn-success ml-auto"
                style={{ width: 200, float: 'right' }}
                onClick={() => setReloads(reloads + 1)}
              >
                Reload Options
              </Button>
              )}
            </Card.Header>
            {!loading ? (
              <Card.Body>
                <Formik
                  enableReinitialize
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={(values, { setSubmitting }) => {
                    const projectData = {
                      ...values,
                      team: values
                        .team
                        .map((t) => t.value),
                    };
                    if (id) {
                      updateProject(projectData);
                    } else {
                      createProject(projectData);
                    }
                    setSubmitting(false);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                    /* and other goodies */
                  }) => (
                    <Form onSubmit={handleSubmit}>
                      <Row>
                        <Col sm={8}>
                          <Form.Label>Project Name</Form.Label>
                          <Form.Control
                            name="name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.name}
                            className={touched.name && errors.name ? 'error' : null}
                          />
                          {errors.name && touched.name && errors.name}
                        </Col>
                        <Col>
                          <Form.Label>Project Code (assigned by funder)</Form.Label>
                          <Form.Control
                            type="text"
                            name="number"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.number}
                            className={touched.number && errors.number ? 'error' : null}
                          />
                          {errors.number && touched.number && errors.number}
                        </Col>
                        <Col>
                          <Form.Label>Show on website</Form.Label>
                          <Form.Check
                            name="published"
                            onChange={(e) => handlePublishedChange(e, setFieldValue)}
                            onBlur={(e) => handlePublishedChange(e, setFieldValue)}
                            checked={values.published}
                            value={values.published}
                            className={touched.published && errors.published ? 'error' : null}
                          />
                          {errors.published && touched.published && errors.published}
                        </Col>
                      </Row>

                      <Row style={{ marginTop: '2rem' }}>
                        <Col>
                          <Form.Label>Begin</Form.Label>
                          <Datetime
                            name="beginDate"
                            value={values.beginDate}
                            timeFormat={false}
                            dateFormat="YYYY-MM-DD"
                            onChange={(date) => handleDatetimeChange(date, 'beginDate', 'YYYY-MM-DD', setFieldValue)}
                            className={touched.beginDate && errors.beginDate ? 'error' : null}
                          />
                          {errors.beginDate && touched.beginDate && errors.beginDate}
                        </Col>
                      </Row>
                      <Row style={{ marginTop: '2rem' }}>
                        <Col>
                          <Form.Label>End</Form.Label>
                          <Datetime
                            name="endDate"
                            value={values.endDate}
                            timeFormat={false}
                            dateFormat="YYYY-MM-DD"
                            onChange={(date) => handleDatetimeChange(date, 'endDate', 'YYYY-MM-DD', setFieldValue)}
                          />
                        </Col>
                      </Row>

                      <Row style={{ marginTop: '2rem' }}>
                        <Col sm={5}>
                          <Form.Label>Type</Form.Label>
                          <Select
                            name="type"
                            options={typesOptions}
                            onChange={(val) => handleTypeChange(val, setFieldValue)}
                            value={typesOptions.find((s) => s.value === values.type)}
                            isDisabled={loading}
                            className={touched.type && errors.type ? 'error' : null}
                          />
                          {errors.type && touched.type && errors.type}
                        </Col>
                        <Col>
                          <Form.Label>Funder</Form.Label>
                          <Select
                            name="funder"
                            options={fundersOptions}
                            onChange={(val) => handleFunderChange(val, setFieldValue)}
                            value={fundersOptions.find((f) => f.value === values.funder)}
                            isDisabled={isFunderLoading}
                            className={touched.funder && errors.funder ? 'error' : null}
                          />
                          {errors.funder && touched.funder && errors.funder}
                        </Col>
                        <Col>
                          <Form.Label>Action</Form.Label>
                          <Form.Control
                            name="action"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.action || ''}
                            className={touched.action && errors.action ? 'error' : null}
                          />
                        </Col>
                      </Row>

                      <Row style={{ marginTop: '2rem' }}>
                        <Col>
                          <Form.Label>Status</Form.Label>
                          <Select
                            name="status"
                            options={projectStatuses}
                            onChange={(val) => handleStatusChange(val, setFieldValue)}
                            value={projectStatuses.find(
                              (s) => s.value === values.status,
                            )}
                            isDisabled={loading}
                            className={touched.status && errors.status ? 'error' : null}
                          />
                          {errors.status && touched.status && errors.status}
                        </Col>
                        <Col>
                          <Form.Label>Acronym</Form.Label>
                          <Form.Control
                            name="acronym"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.acronym || ''}
                            className={touched.acronym && errors.acronym ? 'error' : null}
                          />
                          {errors.acronym && touched.acronym && errors.acronym}
                        </Col>
                        <Col>
                          <Form.Label>SAP code</Form.Label>
                          <Form.Control
                            type="text"
                            name="sap"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.sap || ''}
                            className={touched.sap && errors.sap ? 'error' : null}
                          />
                        </Col>
                      </Row>

                      <Row style={{ marginTop: '2rem' }}>
                        <Col>
                          <Form.Label>Project call</Form.Label>
                          <Form.Text muted>
                            Missing call? Add via Settings -> Project calls
                          </Form.Text>
                          <Select
                            name="call"
                            options={callsOptions}
                            onChange={(val) => handleCallChange(val, setFieldValue)}
                            value={callsOptions.find((s) => s.value === values.call)}
                            isDisabled={loading}
                            className={touched.call && errors.call ? 'error' : null}
                          />
                          {errors.call && touched.call && errors.call}
                        </Col>
                        <Col>
                          <Form.Label>Project Team(s)</Form.Label>
                          <Select
                            name="team"
                            isMulti
                            options={teamsOptions}
                            onChange={(val) => handleTeamChange(val, setFieldValue)}
                            value={values.team}
                            isDisabled={loading}
                            className={touched.team && errors.team ? 'error' : null}
                          />
                          {errors.team && touched.team && errors.team}
                        </Col>
                        <Col>
                          <Form.Label>COSIC Contact (main scientific responsible)</Form.Label>
                          <Select
                            name="cosicContact"
                            onChange={(val) => setFieldValue('cosicContact', val.value)}
                            options={authorsOption}
                            filterOption={customFilterMatchAll}
                            value={values.cosicContact ? authorsOption.find((a) => a.value === values.cosicContact) : null}
                            className={touched.cosicContact && errors.cosicContact ? 'error' : null}
                          />
                          {errors.cosicContact && touched.cosicContact && errors.cosicContact}
                        </Col>
                        <Col>
                          <Form.Label>COSIC role in project</Form.Label>
                          <Select
                            name="cosicContactRole"
                            onChange={(val) => setFieldValue('cosicContactRole', val.value)}
                            options={cosicContactRoles}
                            value={values.cosicContactRole ? cosicContactRoles.find((r) => r.value === values.cosicContactRole) : null}
                            className={touched.cosicContactRole && errors.cosicContactRole ? 'error' : null}
                          />
                          {errors.cosicContactRole && touched.cosicContactRole}
                        </Col>
                        <Col>
                          <Form.Label>COSIC Budget</Form.Label>
                          <Form.Control
                            type="number"
                            name="cosicBudget"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.cosicBudget || 0}
                            className={touched.cosicBudget && errors.cosicBudget ? 'error' : null}
                          />
                          {errors.cosicBudget && touched.cosicBudget && errors.cosicBudget}
                        </Col>
                      </Row>

                      <Row style={{ marginTop: '2rem' }}>
                        <Col>
                          <Form.Label>Abstract</Form.Label>
                          <Form.Control
                            as="textarea"
                            name="abstract"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.abstract}
                            className={touched.abstract && errors.abstract ? 'error' : null}
                          />
                          {errors.abstract && touched.abstract && errors.abstract}
                        </Col>
                      </Row>
                      <Row style={{ marginTop: '2rem' }}>
                        <Col sm={2}>
                          <Form.Label>Link to data repository</Form.Label>
                          <Form.Control
                            name="webVersionRepository"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.webVersionRepository || ''}
                            className={touched.webVersionRepository && errors.webVersionRepository ? 'error' : null}
                          />
                        </Col>
                        <Col sm={2}>
                          <Form.Label>Project website</Form.Label>
                          <Form.Control
                            name="website"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.website || ''}
                            className={touched.website && errors.website ? 'error' : null}
                          />
                          <div className="error-message">
                            <ErrorMessage name="website" />
                          </div>
                        </Col>
                      </Row>
                      <Row style={{ marginTop: '1em' }}>
                        <Button
                          type="submit"
                          variant="primary"
                          className="btn btn-success ml-auto"
                          disabled={isSubmitting}
                          block
                        >
                          {isSubmitting ? 'Submitting...' : confirmationText}
                        </Button>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </Card.Body>
            ) : (<Loader centered />)}
          </Card>
        </Col>
      </Row>

      {id ? showTabs() : <Row />}
    </Container>
  );
};

Project.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  authorsOption: PropTypes.arrayOf(PropTypes.any).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  detail: PropTypes.objectOf(PropTypes.any).isRequired,
  loading: PropTypes.bool.isRequired,
  isFunderLoading: PropTypes.bool.isRequired,
  fetchProjectDetails: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  initialValues: PropTypes.objectOf(PropTypes.any).isRequired,
  fetchAuthors: PropTypes.func.isRequired,
  fetchTypes: PropTypes.func.isRequired,
  fetchFunder: PropTypes.func.isRequired,
  fetchCalls: PropTypes.func.isRequired,
  fetchFiles: PropTypes.func.isRequired,
  fetchPartners: PropTypes.func.isRequired,
  addFile: PropTypes.func.isRequired,
  addPartner: PropTypes.func.isRequired,
  removePartner: PropTypes.func.isRequired,
  addMember: PropTypes.func.isRequired,
  removeMember: PropTypes.func.isRequired,
  fetchPeople: PropTypes.func.isRequired,
  deleteFile: PropTypes.func.isRequired,
  clearForm: PropTypes.func.isRequired,
  downloadFile: PropTypes.func.isRequired,
  createProject: PropTypes.func.isRequired,
  updateProject: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  calls: PropTypes.arrayOf(PropTypes.any),
  // eslint-disable-next-line react/forbid-prop-types
  teams: PropTypes.arrayOf(PropTypes.any),
  // eslint-disable-next-line react/forbid-prop-types
  funders: PropTypes.arrayOf(PropTypes.any),
  // eslint-disable-next-line react/forbid-prop-types
  types: PropTypes.arrayOf(PropTypes.any),
  // eslint-disable-next-line react/forbid-prop-types
  files: PropTypes.arrayOf(PropTypes.any),
  // eslint-disable-next-line react/forbid-prop-types
  numberOfFilesUploading: PropTypes.objectOf(PropTypes.any).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  partners: PropTypes.arrayOf(PropTypes.any).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  people: PropTypes.arrayOf(PropTypes.any).isRequired,
};

Project.defaultProps = {
  calls: [],
  funders: [],
  types: [],
  files: [],
  teams: [],
};

const mapStateToProps = (state) => ({
  authorsOption: state.publication.authorsOption,
  loading: state.project.isLoading,
  detail: selectProjectDetail(state),
  initialValues: state.project.initialValues,
  types: state.project.types,
  calls: state.project.calls,
  teams: state.project.teams,
  funders: state.funders.data,
  isFunderLoading: state.funders.isLoading,
  files: state.project.files,
  numberOfFilesUploading: state.project.numberOfFilesUploading,
  partners: state.partners.data,
  people: state.people.data,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAuthors: () => dispatch(FETCH_PUBLICATION_PEOPLE()),
  clearForm: () => dispatch(CLEAR_PROJECT_FORM()),
  fetchProjectDetails: (id) => dispatch(FETCH_PROJECT(id)),
  createProject: (data) => dispatch(CREATE_ONE_PROJECT(data)),
  updateProject: (data) => dispatch(UPDATE_ONE_PROJECT(data)),
  fetchTypes: () => dispatch(FETCH_PROJECT_TYPES()),
  fetchCalls: () => dispatch(FETCH_PROJECT_CALLS()),
  fetchFunder: () => dispatch(fetchAllFunders()),
  fetchFiles: (id) => dispatch(FETCH_PROJECT_FILES(id)),
  addFile: (data) => dispatch(POST_PROJECT_FILE(data)),
  deleteFile: (data) => dispatch(DELETE_PROJECT_FILE(data)),
  downloadFile: (data) => dispatch(DOWNLOAD_PROJECT_FILE(data)),
  fetchPartners: () => dispatch(fetchAllPartnersAction()),
  addPartner: (data) => dispatch(ADD_PROJECT_PARTNER(data)),
  removePartner: (data) => dispatch(REMOVE_PROJECT_PARTNER(data)),
  addMember: (data) => dispatch(ADD_PROJECT_MEMBER(data)),
  removeMember: (data) => dispatch(REMOVE_PROJECT_MEMBER(data)),
  fetchPeople: (data) => dispatch(FETCH_ALL_FILTERED_PEOPLE(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Project);
