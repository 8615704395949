import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import PropTypes from 'prop-types';
import {
  FETCH_CONFERENCE_BY_YEAR,
  FETCH_JOURNAL_BY_YEAR,
  FETCH_STATUS_HISTORY_COUNT_BY_YEAR,
  FETCH_BEST_PAPER_BY_YEAR,
  FETCH_AWARDS_BY_YEAR,
  FETCH_PROJECTS_RATE,
  FETCH_GRANT_RATE,
  FETCH_BILATERAL_RATE,
  FETCH_TOTAL_RATE,
  FETCH_LOCATION_RATE,
  FETCH_FUNDER_RATE,
} from '../../../state/dashboard/dashboardActions';
import Loader from '../../../component/loader/loader';

function Dashboard(props) {
  const [setup, setSetup] = useState(false);

  const {
    isLdnConf,
    isLdnJournal,
    isStatusHistoryCountLoading,
    isLdnBestPaper,
    isLdnAwards,
    isLdnProjects,
    isLdnGrant,
    isLdnBilateral,
    isLdnLocation,
    isLdnTotal,
    journal,
    conference,
    statusHistoryCount,
    bestPaper,
    awards,
    projectSuccess,
    grantSuccess,
    bilateralSuccess,
    totalSuccess,
    // locations,
    funders,
    years,
    fetchJournals,
    fetchConferences,
    fetchStatusHistoryCountByYear,
    fetchBestPaper,
    fetchAwards,
    fetchProjectRate,
    fetchGrantRate,
    fetchBilateralRate,
    fetchTotalRate,
    fetchLocation,
    fetchFunder,
  } = props;

  useEffect(() => {
    if (!setup && years.length > 0) {
      setSetup(true);
      fetchJournals(years[0]);
      fetchConferences(years[0]);
      fetchStatusHistoryCountByYear(years[0]);
      fetchBestPaper(years[0]);
      fetchAwards(years[0]);
      fetchProjectRate();
      fetchGrantRate();
      fetchBilateralRate();
      fetchTotalRate();
      fetchLocation();
      fetchFunder();
    }
  }, [
    years,
    fetchAwards,
    fetchBestPaper,
    fetchBilateralRate,
    fetchConferences,
    fetchGrantRate,
    fetchJournals,
    fetchLocation,
    fetchFunder,
    fetchStatusHistoryCountByYear,
    fetchProjectRate,
    fetchTotalRate,
    setup,
  ]);

  const handleJournalChange = (event) => {
    fetchJournals(event.target.value);
  };

  const handleConferenceChange = (event) => {
    fetchConferences(event.target.value);
  };

  const handlePeopleChange = (event) => {
    fetchStatusHistoryCountByYear(event.target.value);
    fetchBestPaper(event.target.value);
    fetchAwards(event.target.value);
  };

  return (

    <Container fluid className="dashboard">
      <Row>
        <Col>
          <Card>
            <Card.Header>People Statistics</Card.Header>
            <Card.Body>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>People</th>
                    <th>
                      <Form.Control onChange={handlePeopleChange} as="select">
                        {years.map((year) => <option key={year}>{year}</option>)}
                      </Form.Control>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {isStatusHistoryCountLoading ? (
                    <tr>
                      <td><Loader /></td>
                      <td><Loader /></td>
                    </tr>
                  )
                    : statusHistoryCount.sort((a, b) => ((a.peopleType.name.toLowerCase() < b.peopleType.name.toLowerCase()) ? -1 : 1)).map((stat) => (
                      <tr>
                        <td>{stat.peopleType.name}</td>
                        <td>{stat.count}</td>
                      </tr>
                    ))}
                  <tr>
                    <td>Best papers</td>
                    <td>{isLdnBestPaper ? <Loader /> : bestPaper}</td>
                  </tr>
                  <tr>
                    <td>Awards won</td>
                    <td>{isLdnAwards ? <Loader /> : awards}</td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card>
            <Card.Header>Project Statistics</Card.Header>
            <Card.Body>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Projects</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>SUCCESS RATE PROJECTS</td>
                    <td className="text-center">{isLdnProjects ? <Loader /> : `${projectSuccess.toFixed(1)}%`}</td>
                  </tr>
                  <tr>
                    <td>SUCCESS RATE GRANT</td>
                    <td className="text-center">{isLdnGrant ? <Loader /> : `${grantSuccess.toFixed(1)}%`}</td>
                  </tr>
                  <tr>
                    <td>SUCCESS RATE BILATERAL</td>
                    <td className="text-center">{isLdnBilateral ? <Loader /> : `${bilateralSuccess.toFixed(1)}%`}</td>
                  </tr>
                  <tr>
                    <td>TOTAL SUCCESS RATE</td>
                    <td className="text-center">{isLdnTotal ? <Loader /> : `${totalSuccess.toFixed(1)}%`}</td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card>
            <Card.Header>Project funder statistics</Card.Header>
            <Card.Body>
              {
                isLdnLocation
                  ? <Loader />
                  : (
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>Funder</th>
                          <th>Amount of projects</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          funders.map((funder) => (
                            <tr key={String(funder.funder)}>
                              <td>{funder.funder === null ? 'No funder' : funder.funder}</td>
                              <td>{funder.number}</td>
                            </tr>
                          ))
                        }
                      </tbody>
                    </Table>
                  )
              }
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <Card.Header>Summary Journal</Card.Header>
            <Card.Body>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Journal</th>
                    <th>
                      <Form.Control onChange={handleJournalChange} as="select">
                        {years.map((year) => <option key={year}>{year}</option>)}
                      </Form.Control>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>TOP</td>
                    <td>{isLdnJournal ? <Loader /> : journal.length > 3 && journal[0].count}</td>
                  </tr>
                  <tr>
                    <td>VERY GOOD</td>
                    <td>{isLdnJournal ? <Loader /> : journal.length > 3 && journal[1].count}</td>
                  </tr>
                  <tr>
                    <td>GOOD/OK</td>
                    <td>{isLdnJournal ? <Loader /> : journal.length > 3 && journal[2].count}</td>
                  </tr>
                  <tr>
                    <td>TO AVOID</td>
                    <td>{isLdnJournal ? <Loader /> : journal.length > 3 && journal[3].count}</td>
                  </tr>
                  <tr>
                    <td>SPECIAL</td>
                    <td>{isLdnJournal ? <Loader /> : journal.length > 4 && journal[4].count}</td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card>
            <Card.Header>Summary Conference</Card.Header>
            <Card.Body>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Conferences</th>
                    <th>
                      <Form.Control onChange={handleConferenceChange} as="select">
                        {years.map((year) => <option key={year}>{year}</option>)}
                      </Form.Control>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>TOP</td>
                    <td>{isLdnConf ? <Loader /> : conference.length > 4 && conference[0].count}</td>
                  </tr>
                  <tr>
                    <td>VERY GOOD</td>
                    <td>{isLdnConf ? <Loader /> : conference.length > 4 && conference[1].count}</td>
                  </tr>
                  <tr>
                    <td>GOOD/OK</td>
                    <td>{isLdnConf ? <Loader /> : conference.length > 4 && conference[2].count}</td>
                  </tr>
                  <tr>
                    <td>TO AVOID</td>
                    <td>{isLdnConf ? <Loader /> : conference.length > 4 && conference[3].count}</td>
                  </tr>
                  <tr>
                    <td>SPECIAL</td>
                    <td>{isLdnConf ? <Loader /> : conference.length > 4 && conference[4].count}</td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

Dashboard.propTypes = {
  isLdnConf: PropTypes.bool.isRequired,
  isLdnJournal: PropTypes.bool.isRequired,
  isStatusHistoryCountLoading: PropTypes.bool.isRequired,
  isLdnBestPaper: PropTypes.bool.isRequired,
  isLdnAwards: PropTypes.bool.isRequired,
  isLdnProjects: PropTypes.bool.isRequired,
  isLdnGrant: PropTypes.bool.isRequired,
  isLdnBilateral: PropTypes.bool.isRequired,
  isLdnTotal: PropTypes.bool.isRequired,
  isLdnLocation: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  journal: PropTypes.arrayOf(PropTypes.any).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  conference: PropTypes.arrayOf(PropTypes.any).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  statusHistoryCount: PropTypes.arrayOf(PropTypes.any).isRequired,
  bestPaper: PropTypes.number.isRequired,
  awards: PropTypes.number.isRequired,
  projectSuccess: PropTypes.number.isRequired,
  grantSuccess: PropTypes.number.isRequired,
  bilateralSuccess: PropTypes.number.isRequired,
  totalSuccess: PropTypes.number.isRequired,
  // eslint-disable-next-line react/forbid-prop-types,react/no-unused-prop-types
  locations: PropTypes.arrayOf(PropTypes.any).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  funders: PropTypes.arrayOf(PropTypes.any).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  years: PropTypes.arrayOf(PropTypes.any).isRequired,
  fetchJournals: PropTypes.func.isRequired,
  fetchConferences: PropTypes.func.isRequired,
  fetchStatusHistoryCountByYear: PropTypes.func.isRequired,
  fetchBestPaper: PropTypes.func.isRequired,
  fetchAwards: PropTypes.func.isRequired,
  fetchProjectRate: PropTypes.func.isRequired,
  fetchGrantRate: PropTypes.func.isRequired,
  fetchBilateralRate: PropTypes.func.isRequired,
  fetchTotalRate: PropTypes.func.isRequired,
  fetchLocation: PropTypes.func.isRequired,
  fetchFunder: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isLdnConf: state.dashboard.isLoadingConference,
  isLdnJournal: state.dashboard.isLoadingJournal,
  isLdnVisitors: state.dashboard.isVisitorsLoading,
  isLdnPhd: state.dashboard.isPhdLoading,
  isStatusHistoryCountLoading: state.dashboard.isStatusHistoryCountLoading,
  isLdnBestPaper: state.dashboard.isBestPaperLoading,
  isLdnAwards: state.dashboard.isAwardsLoading,
  isLdnProjects: state.dashboard.isProjectsLoading,
  isLdnGrant: state.dashboard.isGrantLoading,
  isLdnBilateral: state.dashboard.isBilateralLoading,
  isLdnTotal: state.dashboard.isTotalLoading,
  isLdnLocation: state.dashboard.isLocationLoading,
  isLdnFunder: state.dashboard.isFunderLoading,
  journal: state.dashboard.journal,
  conference: state.dashboard.conference,
  statusHistoryCount: state.dashboard.statusHistoryCount,
  bestPaper: state.dashboard.bestPaper,
  awards: state.dashboard.awards,
  projectSuccess: state.dashboard.projectSuccess,
  grantSuccess: state.dashboard.grantSuccess,
  bilateralSuccess: state.dashboard.bilateralSuccess,
  totalSuccess: state.dashboard.totalSuccess,
  locations: state.dashboard.location,
  funders: state.dashboard.funder,
  years: state.app.years,
});

const mapDispatchToProps = (dispatch) => ({
  fetchJournals: (value) => dispatch(FETCH_JOURNAL_BY_YEAR(value)),
  fetchConferences: (value) => dispatch(FETCH_CONFERENCE_BY_YEAR(value)),
  fetchStatusHistoryCountByYear: (value) => dispatch(FETCH_STATUS_HISTORY_COUNT_BY_YEAR(value)),
  fetchBestPaper: (value) => dispatch(FETCH_BEST_PAPER_BY_YEAR(value)),
  fetchAwards: (value) => dispatch(FETCH_AWARDS_BY_YEAR(value)),
  fetchProjectRate: () => dispatch(FETCH_PROJECTS_RATE()),
  fetchGrantRate: () => dispatch(FETCH_GRANT_RATE()),
  fetchBilateralRate: () => dispatch(FETCH_BILATERAL_RATE()),
  fetchTotalRate: () => dispatch(FETCH_TOTAL_RATE()),
  fetchLocation: () => dispatch(FETCH_LOCATION_RATE()),
  fetchFunder: () => dispatch(FETCH_FUNDER_RATE()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
