import { put, call, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { Store as store } from 'react-notifications-component';
import axios from '../../axios';
import {
  QueryOneType,
  MutateCreateType,
  MutateUpdateType,
} from '../../graphql/queries/types';
import {
  FETCH_ONE_TYPE,
  fetchOneTypeError,
  fetchOneTypeSuccess,
  UPDATE_TYPE,
  updateTypeError,
  updateTypeSuccess,
} from './typeActions';
import catchErrors from '../../graphql/sagas';

export function* getOneType(action) {
  if (action.data.id) {
    const response = yield call(axios.post, '/', { query: QueryOneType(action.data.id) });
    yield put(fetchOneTypeSuccess(response.data.data.type));
  } else {
    yield put(fetchOneTypeSuccess({
      id: null,
      name: '',
      parent: null,
    }));
  }
}

export function* updateType(action) {
  let query;

  if (action.data.id) {
    query = MutateUpdateType(action.data);
  } else {
    query = MutateCreateType(action.data);
  }

  yield call(axios.post, '/', { query });
  yield put(updateTypeSuccess());
  yield put(push('/admin/settings/projecttypes'));

  store.addNotification({
    title: 'SUCCESS!',
    message: 'Update successfully saved!',
    type: 'success',
    insert: 'top',
    container: 'top-right',
    dismiss: {
      duration: 5000,
      onScreen: true,
    },
  });
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(
    FETCH_ONE_TYPE,
    catchErrors({
      onErrorAction: fetchOneTypeError,
      overrideErrorMessage: 'Unable to retrieve type',
    })(getOneType),
  );

  yield takeLatest(
    UPDATE_TYPE,
    catchErrors({
      onErrorAction: updateTypeError,
      overrideErrorMessage: 'Unable to update type',
    })(updateType),
  );
}
