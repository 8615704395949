import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import { Store as store } from 'react-notifications-component';
import axios, { axiosClientNoGraphl } from '../../axios';
import { events } from '../../graphql/queries';
import catchErrors from '../../graphql/sagas';
import {
  FETCH_ALL_EVENTS,
  fetchAllEventsError,
  fetchAllEventsSuccess,
  SEND_MAIL,
  sendMailError,
  sendMailSuccess,
} from './eventsActions';
import config from '../../config/config';

const { QueryAllEvents } = events;

export function* getAllEvents() {
  const response = yield call(axios.post, '/', { query: QueryAllEvents });
  yield put(fetchAllEventsSuccess(response.data.data.events));
}

export function* sendEmail(action) {
  const eventObject = action.data.values.event;
  const subject = `COSIC event announcement: "${eventObject.title}" on ${eventObject.date}`;

  const body = `"${eventObject.title}" will take place on ${eventObject.date} from ${eventObject.start} until ${eventObject.end} at ${eventObject.location} ${eventObject.city}, ${eventObject.country}.\n\n${eventObject.description}\n\nFind out more on ${eventObject.url}`;
  yield call(axiosClientNoGraphl.post, `${config.API_URL}/mail/send`, {
    subject,
    body,
    to: action.data.emailAddresses,
  });

  yield put(sendMailSuccess());
  store.addNotification({
    title: 'SUCCESS!',
    message: 'Mail(s) were sent',
    type: 'success',
    insert: 'top',
    container: 'top-right',
    dismiss: {
      duration: 5000,
      onScreen: true,
    },
  });
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(
    FETCH_ALL_EVENTS,
    catchErrors({
      onErrorAction: fetchAllEventsError,
      overrideErrorMessage: 'Unable to retrieve events',
    })(getAllEvents),
  );

  yield takeLatest(
    SEND_MAIL,
    catchErrors({
      onErrorAction: sendMailError,
      overrideErrorMessage: 'Unable to send email',
    })(sendEmail),
  );
}
