import { call, put } from 'redux-saga/effects';
import { Store as store } from 'react-notifications-component';
import { failedNotificationTimer, successNotificationTimer } from '../config/constants';

export const catchGenericError = (
  { onErrorAction = null, overrideErrorMessage = null, includeTechnicalError = false } = {},
) => (fn) => function* (...args) { // eslint-disable-line func-names
  try {
    yield call(fn, ...args);
  } catch (error) {
    if (onErrorAction !== null) {
      yield put(onErrorAction(error.message));
    }

    store.addNotification({
      title: 'Something went wrong',
      message: includeTechnicalError ? (`${overrideErrorMessage} (${error.message})`) : (overrideErrorMessage || error.message),
      type: 'danger',
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: failedNotificationTimer,
        onScreen: true,
      },
    });
  }
};

export function showSuccessMessage(message) {
  store.addNotification({
    title: 'SUCCESS!',
    message,
    type: 'success',
    insert: 'top',
    container: 'top-right',
    dismiss: {
      duration: successNotificationTimer,
      onScreen: true,
    },
  });
}

const catchErrors = (onErrorAction) => catchGenericError(onErrorAction);

export default catchErrors;
