import { put, call, takeLatest } from 'redux-saga/effects';
import { Store as store } from 'react-notifications-component';
import { push } from 'connected-react-router';
import axios from '../../axios';
import {
  QueryOneFunder,
  MutateCreateFunder,
  MutateUpdateFunder,
} from '../../graphql/queries/funders';
import {
  fetchOneFunderSuccess,
  fetchOneFunderError,
  FETCH_ONE_FUNDER,
  updateFunderSuccess,
  updateFunderError,
  UPDATE_FUNDER,
} from './funderActions';
import catchErrors from '../../graphql/sagas';

export function* getOneFunder(action) {
  const response = yield call(axios.post, '/', { query: QueryOneFunder(action.data.id) });
  yield put(fetchOneFunderSuccess(response.data.data.funder));
}

export function* updateOneFunder(action) {
  const { id } = action.data;
  const msg = id ? 'updated' : 'saved';
  const query = id ? (
    MutateUpdateFunder(action.data)
  ) : MutateCreateFunder(action.data);

  yield call(axios.post, '/', { query });
  yield put(updateFunderSuccess());
  yield put(push('/admin/settings/funders'));

  store.addNotification({
    title: 'SUCCESS!',
    message: `Funder successfully ${msg}!`,
    type: 'success',
    insert: 'top',
    container: 'top-right',
    dismiss: {
      duration: 5000,
      onScreen: true,
    },
  });
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(
    FETCH_ONE_FUNDER,
    catchErrors({
      onErrorAction: fetchOneFunderError,
      overrideErrorMessage: 'Unable to retrieve funder',
    })(getOneFunder),
  );

  yield takeLatest(
    UPDATE_FUNDER,
    catchErrors({
      onErrorAction: updateFunderError,
      overrideErrorMessage: 'Unable to update funder',
    })(updateOneFunder),
  );
}
